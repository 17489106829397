
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Pagination, notification, Select, DatePicker, Tooltip, Checkbox, Form } from 'antd';
import 'antd/dist/antd.css';
import API_SERVICE from '../../utils/apiService';
import searchIcon from "../../assets/images/ic-search.svg";
import {
  SYNC_TOKEN,
  SYNC_TOKEN_MHEA,
  SYNC_TOKEN_POSTLOGIN,
  PATIENT_QUEUE,
  LOADER_RED,
  SEARCH_DRUGS,
  USERTYPES,
  PRACTICE_STATUS,
  TODAYS_VISIT,
  SOAP_CONFIGURATION_LIST,
  GET_PRESCRIPTION_BY_REFERENCE,
  SEARCH_DRUGS_FILTERED,
  HOSPITAL_CONFIG,
  APPS_FEATURES_LIST,
  BASIC,
  MYHC_APPTYPE,
  MHVCMARKETPLACE,
  VITALS_FILTER_SEQUENCING_LIST,
  MHEA,
  TOGGLE_PRACTICE,
  UHID,
  GETGENDER,
  HOMECARE_PATIENTLIST,
  GET_VITALS_RANGE_CLASS,
  ISALLOWEDITSOAP,
  SOAP_TYPE_FETCH,
  TAB_NAMES,
  is_chat_enabled,
  HOMECARE_REFERENCE_TYPE,
  PROFILE,
  NURSE_MARK_ARRIVAL,
  GET_ARRAY_BUFFER_FORM_URL,
  UPDATE_QMS_STATUS,
  UPDATE_QMS_STATUS_NURSE,
  NURSE_CURRENT_PATIENT,
  PATIENT_CHECKOUT,
  CHECKOUT_HOSPITALS_ENABLED,
  SINGLE_SCREEN_CONFIGURATION_FETCH,
  CALL_HOMECARE_PATIENT,
  IPD_SLOT_DETAILS
} from '../../utils/constant';
import Axios from 'axios';
import LS_SERVICE from '../../utils/localStorage';
import AppHelpers from '../../utils/AppHelpers';
import qs from 'qs';
import moment from 'moment';
import { practiceService, queuePracticeService } from '../../utils/rxjs-sharing';
import { Spin } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { headerService$, vcOpdEnabledSubjectService, assistanceNumberService , hideExtraTab } from '../../utils/rxjs-sharing';
import chat from '../../assets/images/ic-chat-pubnub.svg';
import SEARCH_ICO from '../../assets/images/ic-search.svg';
import EMERGENCY_ICO from '../../assets/images/icon-feather-alert-triangle.svg';
import { pubnubService$ } from "../../utils/pubnub-sharing";
import { jQueryLabels } from '../../utils/jQueryLabels';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Link } from 'react-router-dom';
import QueueCalender from "./queue_calender";
import { data } from 'jquery';

import syncIcon from "../../assets/images/arrows-refresh-2847.svg"
import cancelIcon from "../../assets/images/Group 253.svg"
import playIcon from "../../assets/images/Group 277.svg"
import nextIcon from "../../assets/images/Group 252.svg"
import doctorIcon from "../../assets/images/doctor icon.png"

import wheelchairIcon from '../../assets/images/icon-wheelchair.svg'
import stretcherIcon from "../../assets/images/icon-stretcher.svg"
import oxygenIcon from "../../assets/images/icon-02.svg"
import commentIcon from "../../assets/images/ic-Remarks.svg"
import hwcIcon from "../../assets/images/hwc-icon.png"
import vipIcon from '../../assets/images/vip-icon.png'
import vulnarableIcon from '../../assets/images/vulnarable-icon.png'

import Bowser from "bowser";

const { Option } = Select;

const antIcon = <SyncOutlined style={{ fontSize: 24 }} className="syncoutline_icon" spin />;

let hitOnlyOnce = true;
const cancelHomecareToken = Axios.CancelToken;

let cancelHC;

class SearchPatient extends Component {

  state = {
    isModalVisible: false,
    practiceNotStarted: false,
    goToPatient: {},
    loading: true,
    slots: [],
    patients: [],
    homecare_patients: [],
    constants: null,
    homecareVitals: null,
    worklist_count: 0,
    review_count: 0,
    completed_count: 0,
    pending_count: 0,
    homecare_count: 0,
    homecare_upcoming_count: 0,
    homecare_completed_count: 0,
    incomplete_tokens: 0,
    showSpinner: false,
    goToWorklist: true,
    showRxModal: false,
    rxPdfPath: null,
    goToSoapPatientUrl: null,
    nurseDoctors: [],
    nurseDoctorId: [],
    nurse_onhold_patients: [],
    nurse_waiting_patients: [],
    queueInitialized: false,
    fetchWorklistCount: 0,
    homecare_currentpage: 1,
    homecare_totaldata: 0,
    homecare_totalpages: 0,
    patientQueueHitAfter10secs: false,
    activeTab: TAB_NAMES[0],
    nurse_homecare: false,
    hospital_id: null,
    current_patient: null,
    nurse_current_patient: [],
    is_doctor_homecare_active: false,
    homecare_page: 1,
    per_page: 10,
    dataLoading: true,
    hcCompSearch: '',
    first_hc_load: true,
    is_mark_arrival: 0,
    patientLoad: false,
    selectedDoctorIdAppoinement: 0,
    is_vc_opd_enabled: 0,
    isNurseLoggined: false,
    is_qms_enabled: false,
    is_next_call_enabled: false,
    arrayOfWeekdays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    queue_date: moment().format('YYYY-MM-DD'),
    isCalendarEnabeld: false,
    showLoader: false,
    pdfReferenceId: null,
    disabledCheckoutBookings: [],
    doctorSelectionModal: false,
    skipTokenIds: [],
    forworedTokenIds: [],
    search_keyword: '',
    is_junior_doctor: false,
    senior_doctor: [],
    seniorDoctorId: [],
    pdfReferenceUrl : null,
    rxPdfPrint: false,
  };


  constructor(props) {
    super(props)
    this.onClick = this.gotoPending.bind();
    this.tabContentShowTimer = '';

    this.onSearch$ = new Subject();
    this.cardsLimitScroll = {
      worklist: {
        inListCount: 0,
        upperLimit: 10
      },
      completed: {
        inListCount: 0,
        upperLimit: 10
      },
      pending: {
        inListCount: 0,
        upperLimit: 10
      }
    };

    this.isLoadingOnScroll = false;
    this.handlePageScroll = this.handlePageScroll.bind(this);
    this.handleAppointmentDate = this.handleAppointmentDate.bind(this);
  }

  async componentDidMount() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get('token');
    jQueryLabels();
    if (LS_SERVICE.get('user_type') == USERTYPES.nurse) {
      this.setState({
        isNurseLoggined: true
      });
    }
    if(LS_SERVICE.get('STORE_ID') == 0){
      const ipdHospitalData = await Axios.post(IPD_SLOT_DETAILS, {
        doctorId: parseInt(LS_SERVICE.get('staff_id')),
        //doctorId: 362
        timeZoneMinute: parseInt(LS_SERVICE.get('TIMEZONE_OFFSET_MINUTES')),
        statusId: LS_SERVICE.get('STATUS_ID'),
        hospitalId: parseInt(LS_SERVICE.get('slot_hospital_id'))
      });
      if (typeof ipdHospitalData?.data?.data?.result?.slots != 'undefined' && ipdHospitalData?.data?.data?.result?.slots?.length > 0) {
        this.setState({
          ipdSlots: ipdHospitalData?.data?.data?.result?.slots,
          ipdhospitalsCount: ipdHospitalData?.data?.data?.result?.slots?.length || 0
        });
        LS_SERVICE.set('ipd_slots', ipdHospitalData?.data?.data?.result?.slots || []);

        ipdHospitalData.data.data.result.slots.map((slot) => {
          if (slot.hospitalId == LS_SERVICE.get('slot_hospital_id')) {
            let storeId = slot?.storeId || 0;
            LS_SERVICE.set('STORE_ID',storeId);
          }
        });
      }
    }
    if(!LS_SERVICE.get('favouriteMedicines')){
      let drug_params = {search: ''};
      if(LS_SERVICE.get("user_type") == USERTYPES.admin){
        drug_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null
      } else {
        drug_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
      }
      drug_params.store_id = LS_SERVICE.get("STORE_ID");
      drug_params.facility_id = LS_SERVICE.get("FACILITY_ID");
      drug_params.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");
      Axios.post(SEARCH_DRUGS_FILTERED, qs.stringify(drug_params)).then(success => {
        LS_SERVICE.set('favouriteMedicines', success.data.medicine_list);
      }).catch(err => console.log(err))
    }

    let referenceId = this.props.location.pathname.substr(1).split('=')[1];
    this.setState({
      showRxModal: (referenceId != undefined && referenceId != null) ? true : false,
      showLoader: (referenceId != undefined && referenceId != null) ? true : false,
      pdfReferenceId: referenceId
    });

    this.componentDidMountFn();
    this.checkHospitalSlot();
    this.scrollTimer = null;
    window.addEventListener("scroll", this.handlePageScroll, false);
  }


  componentDidMountFn = () => {
    const { match: { params }, location: { state } } = this.props;
    const hospital_id = params.id.split('?')[0];
    let is_junior_doctor;
    if (LS_SERVICE.get('is_junior_doctor') != undefined) {
      is_junior_doctor = LS_SERVICE.get('is_junior_doctor');
      this.setState({ is_junior_doctor, is_junior_doctor });
    }
    if (LS_SERVICE.get('senior_doctor') != undefined) {
      let senior_doctor = LS_SERVICE.get('senior_doctor');
      // let seniorDoctorId = [];
      // if (senior_doctor.length > 0) {
      //   senior_doctor.map((doctor) => { seniorDoctorId.push(doctor.id) })
      // }
      this.setState({ senior_doctor })
    }

    if (LS_SERVICE.get('senior_doctor_id') != undefined) {
      let seniorDoctorId = LS_SERVICE.get('senior_doctor_id')
      this.setState({ seniorDoctorId })

    }
    this.setState({
      hospital_id: hospital_id,
      is_qms_enabled: (hospital_id == 4) ? true : false,
      nurse_homecare: LS_SERVICE.get('nurse_homecare') || false,
    }, () => {
      this.setHospitalToStorage();
      this.initQueue(); // Commenting on 9 Aug 2022 and loading only patient queue
      // this.loadPatientQueue();
      // let activeTab = TAB_NAMES[0];
      // if(state?.activeTab !== undefined) {
      //   activeTab = state?.activeTab
      // } else if (this.state.nurse_homecare)
      this.setState({
        activeTab: state?.activeTab !== TAB_NAMES[1] ? (this.state.nurse_homecare ? TAB_NAMES[3] : (LS_SERVICE.get('activeTab') || TAB_NAMES[0])) : TAB_NAMES[1]
      }, () => this.handleTabChange(null, this.state.activeTab))
    });
     if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
      this.handleBasicOrSoap();
      this.initSoapList();
      this.setState({ is_vc_opd_enabled: 1 });
    } else {

      let doctorIds = [];

      if (LS_SERVICE.get('nurse_doctor_id')) {
        if (Number.isInteger(LS_SERVICE.get('nurse_doctor_id'))) {
          doctorIds = [LS_SERVICE.get('nurse_doctor_id')];
          LS_SERVICE.set('nurse_doctor_id', doctorIds)
        } else {
          doctorIds = LS_SERVICE.get('nurse_doctor_id');
        }
      }

      let nurseDoctors = [];
      let nurse_doctors = LS_SERVICE.get('nurse_doctors');
      nurse_doctors.map((doctorData) => {
        if (Object.keys(doctorData.specialityDetail).length > 0) {
          Object.keys(doctorData.specialityDetail).map((specialityId, index) => {
            const obj = {};
            obj['speciality_id'] = specialityId;
            obj['speciality_name'] = Object.values(doctorData.specialityDetail)[index];
            obj['data'] = [doctorData];

            if (nurseDoctors.length > 0 && nurseDoctors.find(element => element.speciality_id == specialityId)) {
              nurseDoctors.map((element) => {
                if (element.speciality_id == specialityId) {
                  element.data.push(doctorData);
                }
              });
            } else {
              nurseDoctors.push(obj);
            }
          });
        }
      });

      this.setState({
        nurseDoctors: LS_SERVICE.get('nurse_doctors'),
        nurseDoctorsList: nurseDoctors,
        nurseDoctorId: doctorIds
      });

      this.vcOpdEnabledServiceSubscription = vcOpdEnabledSubjectService.status().subscribe(data => {
        this.setState({ is_vc_opd_enabled: data.isVcOpdEnabled });
      });
      this.setState({ is_vc_opd_enabled: LS_SERVICE.get("nurse_is_vc_opd_enabled") });
    }

    this.subscription = queuePracticeService.status().subscribe(result => {
      const arrRoute = this.props.location.pathname.split('/');
      const hid = arrRoute[arrRoute.length - 1].split('?')[0];

      if (result.headerStart) {
        const patient = this.state.goToPatient;
        // if (patient.patient_id === undefined) {
        //   return;
        // }
        this.setState({
          goToPatient: {},
          practicing: {
            status: true,
            hospital_id: hid
          }
        }, () => {
          if (patient.patient_id !== undefined) {
            this.callNext(patient);
          }
        });
      } else {
        this.setState({
          practicing: {
            status: false,
            hospital_id: hid
          }
        });
      }
    });

    this.searchSubscription = this.onSearch$.pipe(
      debounceTime(1000)
    )
      .subscribe(success => this.getHomecareCards(1, this.state.per_page, this.state.activeTab));

    this.refreshWorklist = setInterval(() => {
      this.initQueueInterval();
    }, 120000);


    if (is_chat_enabled) {
      this.handlePubNubServiceSubscription = pubnubService$.pubnub_observe().subscribe(success => {
        if (success.pubnub_message_counts) {
          this.setState({
            pubnub_message_counts: success.pubnub_message_counts
          }, () => {
            if (hitOnlyOnce) {
              // this.loadListData();
              // this.addScrollEvent();
              hitOnlyOnce = false;
            } else {
              // this.loadApiData();
            }
          })
        }
      })
    }

    // Remove appointment date
    LS_SERVICE.set('appointment_date', '');
    // this.tabContentShow();
  }

  checkHospitalSlot(){
    let slot_hospitals = LS_SERVICE.get('slot_hospitals');
    if(slot_hospitals != undefined && slot_hospitals !=null){
      this.setState({
        slots:slot_hospitals ||[]
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handlePageScroll, false);
    this.vcOpdEnabledServiceSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
    this.searchSubscription?.unsubscribe();
    clearInterval(this.refreshWorklist);
    if (is_chat_enabled) this.handlePubNubServiceSubscription?.unsubscribe();
  }

  gotoWorklist = () => {
    this.setState({ isModalVisible: false, goToWorklist: false });
  }

  gotoPending = () => {
    this.setState({ isModalVisible: false });

    ReactDOM.findDOMNode(this.refs.WorklistTab).classList.remove('active');
    ReactDOM.findDOMNode(this.refs.WorklistPanel).classList.remove('active', 'show');

    ReactDOM.findDOMNode(this.refs.PendingTab).classList.add('active');
    ReactDOM.findDOMNode(this.refs.PendingPanel).classList.add('active', 'show');
  }

  handleBasicOrSoap = async () => {

    const { match: { params } } = this.props;
    let { id } = params;
    id = id.split('?')[0];

    this.setState({
      hospital_id: id.split('?')[0]
    })

    const PARAMS = {
      hospital_id: id,
      speciality_id: LS_SERVICE.get('speciality_id'),
      staff_id: LS_SERVICE.get('staff_id')
    }

    var success;
    if(LS_SERVICE.get("hospitalConfigDataResponse")?.data){
        success = LS_SERVICE.get("hospitalConfigDataResponse");
    }
    else{
        success = await Axios.post(HOSPITAL_CONFIG, qs.stringify(PARAMS));
        LS_SERVICE.set("hospitalConfigDataResponse", {data: success.data})
    }
      // .then(success => {
        let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
        let hospitalConfiguration = hospitalConfigData.find(item => item.hospital_id == id);
        if (hospitalConfiguration) {
          hospitalConfigData.forEach((hcd, idx) => {
            if (+hcd.hospital_id == +id) {
              hospitalConfigData[idx].opd_layout = success.data.data.opd_layout;
              hospitalConfigData[idx].pres_modification = success.data.data.pres_modification;
              hospitalConfigData[idx].allow_hours = success.data.data.allow_hours;
              hospitalConfigData[idx].duplicate_order = success.data.data.duplicate_order;
            }
          })
        } else {
          hospitalConfigData.push({
            hospital_id: id,
            opd_layout: success.data.data.opd_layout,
            pres_modification: success.data.data.pres_modification,
            allow_hours: success.data.data.allow_hours,
            duplicate_order: success.data.data.duplicate_order
          })
        }
        this.setState({
          pres_modification: success.data.data.pres_modification,
          allow_hours: success.data.data.allow_hours
        }, () => {
          // this.initQueue(); // Commenting on 9 Aug 2022 and loading only patient queue
          this.loadPatientQueue();
        })

        LS_SERVICE.set('hospitalConfigData', hospitalConfigData);
        headerService$.soapConfig({ soapConfig: success.data.data.opd_layout });
        this.setState({
          soapConfig: success.data.data.opd_layout
        })
      // })
      // .catch(error => console.log(error))

    const is_doctor = LS_SERVICE.get('user_type') == USERTYPES.doctor;
    if(LS_SERVICE.get("profile")?.data){
      LS_SERVICE.set('isPaediatrician', LS_SERVICE.get("profile")?.data?.paediatric || 0)
    }
    else{
      Axios.post(PROFILE({ is_doctor }), qs.stringify())
      .then(response => {
        LS_SERVICE.set('isPaediatrician', response.data.data.paediatric || 0)
        LS_SERVICE.set("profile",{data: response?.data?.data});
      }).catch(error => console.log(error))
    }
  }

  async initQueue() {
    if (APPS_FEATURES_LIST.select_doctor_dropdown && LS_SERVICE.get('user_type') == USERTYPES.nurse && LS_SERVICE.get('nurse_doctor_id') == null) {
      //return;
    }
    // if (this.state.fetchWorklistCount === 0) {
    //   this.setState({ loading: true, queueInitialized: true, fetchWorklistCount: this.state.fetchWorklistCount + 1 });
    // }

    const arrRoute = this.props.location.pathname.split('/');
    const hid = arrRoute[arrRoute.length - 1].split('?')[0];
    const { seniorDoctorId }=this.state;

    let syncData = null;

    var url;
    var config = LS_SERVICE.get('emr_server_config');
    var app_env = config?.app_env;
    var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];
    if((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All')))){
      url = SYNC_TOKEN_MHEA;
    }
    else{
      url = SYNC_TOKEN;
    }
    if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
      let pstatus = false;
      if(LS_SERVICE.get('practicing')?.hospital_id == hid){
        if (typeof LS_SERVICE.get('practicing')?.status !== 'undefined' && LS_SERVICE.get('practicing')?.status === true) {
          pstatus = true;
        }
      }
      else{
        const practise = await API_SERVICE.post(PRACTICE_STATUS, { hospital_id: hid });
        if (typeof practise.is_practicing !== 'undefined' && practise.is_practicing === 'Y') {
          pstatus = true;
        }
      }
      let postData = { staff_id: LS_SERVICE.get('staff_id'), hospital_id: this.state.hospital_id, queue_date: this.state.queue_date }

      // if (this.state.seniorDoctorId.length > 0) {
      //   let doctor_id = LS_SERVICE.get('senior_doctor_id') || [];
      //   let is_junior_doctor = this.state.is_junior_doctor.toString();
      //   postData ={staff_id: LS_SERVICE.get('staff_id'), doctor_id: doctor_id, is_junior_doctor ,queue_date:this.state.queue_date }
      // }
      
      if (seniorDoctorId.length > 0) {
        let doctor_id = seniorDoctorId || [];
        let is_junior_doctor = this.state.is_junior_doctor.toString();
        postData.doctor_id = doctor_id;
        postData.is_junior_doctor = is_junior_doctor;
      }
      this.setState({ practicing: { status: pstatus, hospital_id: hid } });
      syncData = await API_SERVICE.post(url, postData);
      if((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All')))){
        LS_SERVICE.set('sync_token_mhea',{data: syncData})
      }
      if (seniorDoctorId.length == 0 || seniorDoctorId == undefined) {
        if (syncData && syncData.is_junior_doctor) {
          this.setState({ is_junior_doctor: syncData.is_junior_doctor });
        }
        if (syncData && syncData.senior_doctor) {
          this.setState({ senior_doctor: syncData.senior_doctor });
        }
        if (syncData && syncData.slots) {
          AppHelpers.setDashboardSlots(syncData);
        }
      }
    } else if (LS_SERVICE.get('nurse_doctor_id') != null) {
      this.setState({ practicing: { status: false, hospital_id: hid } });
      syncData = await API_SERVICE.post(url, {
        staff_id: LS_SERVICE.get('staff_id'),
        hospital_id: String(LS_SERVICE.get('slot_hospital_id')),
        queue_date: this.state.queue_date
      });
      vcOpdEnabledSubjectService.init({
        isVcOpdEnabled: syncData.is_vc_opd_enabled ? syncData.is_vc_opd_enabled : 0
      });
      this.vcOpdEnabledServiceSubscription = vcOpdEnabledSubjectService.status().subscribe(data => {
        this.setState({ is_vc_opd_enabled: data.isVcOpdEnabled });
      })
    }

    if (seniorDoctorId.length == 0 || seniorDoctorId == undefined) {
      let selectedHospital = syncData && syncData.slots ? syncData.slots.find(item => { return item.id == hid }) : {};
      let is_doctor_homecare_active = selectedHospital?.is_doctor_homecare_active || false;
      let previous_appointment_date = selectedHospital?.previous_appointment_date;
      let next_appointment_date = selectedHospital?.next_appointment_date;

      this.setState({
        slots: syncData?.slots || [],
        is_doctor_homecare_active,
        previous_date: previous_appointment_date,
        next_date: next_appointment_date,
      })
    }

    if(seniorDoctorId.length>0){
      let previous_date = moment(this.state.queue_date).subtract(1,'day').format('YYYY-MM-DD');
      let next_date = moment(this.state.queue_date).add(1,'day').format('YYYY-MM-DD');
      this.setState({
        previous_date: previous_date,
        next_date: next_date,
      })
    }

    await this.loadPatientQueue();

    this.setState({
      pdfReferenceId: null
    })

    /**
     * We are calling patient Queue again in order to fetch
     * Prescription PDF, which takes time to get generated after
     * prescription is completed
     */
    if (!this.state.patientQueueHitAfter10secs) {
      setTimeout(() => {
        this.loadPatientQueue();
        this.setState({ patientQueueHitAfter10secs: true });
      }, 10000);
    }
    return;
  }

  async initQueueInterval() {
    const arrRoute = this.props.location.pathname.split('/');
    const hid = arrRoute[arrRoute.length - 1].split('?')[0];
    const { seniorDoctorId }=this.state;
    var config = LS_SERVICE.get('emr_server_config');
    var app_env = config?.app_env;
    var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];

    if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
      let pstatus = false;
      if(LS_SERVICE.get('practicing')?.hospital_id == hid){
        if (typeof LS_SERVICE.get('practicing')?.status !== 'undefined' && LS_SERVICE.get('practicing')?.status === true) {
          pstatus = true;
        }
      }
      else{
        const practise = await API_SERVICE.post(PRACTICE_STATUS, { hospital_id: hid });
        if (typeof practise.is_practicing !== 'undefined' && practise.is_practicing === 'Y') {
          pstatus = true;
        }
      }
      let postData = { staff_id: LS_SERVICE.get('staff_id'), hospital_id: this.state.hospital_id, queue_date: this.state.queue_date }
      if (seniorDoctorId.length > 0) {
        let doctor_id = seniorDoctorId || [];
        let is_junior_doctor = this.state.is_junior_doctor.toString();
        postData.doctor_id = doctor_id;
        postData.is_junior_doctor = is_junior_doctor;
      }
      this.setState({ practicing: { status: pstatus, hospital_id: hid } });
      if((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All')))){
        // NO PORTAL
      }
      else{
        API_SERVICE.post(SYNC_TOKEN_POSTLOGIN, postData);
      }
    } else if (LS_SERVICE.get('nurse_doctor_id') != null) {
      this.setState({ practicing: { status: false, hospital_id: hid } });
      if((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All')))){
        // NO PORTAL
      }
      else{
        API_SERVICE.post(SYNC_TOKEN_POSTLOGIN, {
          staff_id: LS_SERVICE.get('staff_id'),
          queue_date: this.state.queue_date
        });
      }
    }

    await this.loadPatientQueue();

    return;
  }

  getHomecareCards = async (page, per_page, tab, autoRefresh = false) => {
    var url;
    var config = LS_SERVICE.get('emr_server_config');
    var app_env = config?.app_env;
    var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];
    if((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All')))){
      // NO PORTAL
    }
    else{
    
      const { hospital_id, hcCompSearch } = this.state;

      this.setState({
        dataLoading: autoRefresh ? false : true
      })

      this.setState({
        dataLoading: autoRefresh ? false : true
      })

      const vitalFilterPromise = await Axios.get(VITALS_FILTER_SEQUENCING_LIST);
      if (cancelHC !== undefined) {
        cancelHC();
      }

      return Axios.get(HOMECARE_PATIENTLIST({ booking_status: tab === TAB_NAMES[4] ? 2 : 1, hospital_id, date: moment().format('YYYY-MM-DD'), page, per_page, search: hcCompSearch }),
        {
          cancelToken: new cancelHomecareToken(function executor(c) {
            cancelHC = c;
          }),
        }
      )
      .then(success => {
        const { data } = success;

        const { constants, pagination, appointments, filter_count } = data;
        const allVitals = constants ? constants.vitals_details : null;
        var homecareVitals = [];
        if (vitalFilterPromise?.data?.status) {
          for(var hcvitals of allVitals){
            if(hcvitals.vital_id == 5){
              var i = vitalFilterPromise?.data?.data.map(e => e.vital_id).indexOf(hcvitals.vital_id);
              if(i >= 0){
                var bp = allVitals.find(x => x.vital_id == '6');
                homecareVitals.push(bp)
                homecareVitals.push(hcvitals)
              }
            }
            else{
              var i = vitalFilterPromise?.data?.data.map(e => e.vital_id).indexOf(hcvitals.vital_id);
              if(i >= 0){
                homecareVitals.push(hcvitals)
              }
            }
          }
        }

        this.setState({
          homecare_patients: appointments,
          homecare_upcoming_count: filter_count?.upcoming,
          homecare_completed_count: filter_count?.completed,
          constants,
          homecareVitals,
          homecare_currentpage: pagination?.current_page,
          homecare_totaldata: pagination?.total,
          homecare_totalpages: pagination?.total_pages,
        })
      })
      .catch(fin => {
        this.setState({
          dataLoading: false,
          first_hc_load: false
        })
      })
    }
  }

  async loadPatientQueue(type = null) {
    const { hospital_id, queue_date, nurse_homecare, pdfReferenceId, per_page, nurseDoctorId, seniorDoctorId } = this.state;
    let postdata;
    let userType = '';

    let referenceId = this.props.location.pathname.substr(1).split('=')[1];

    if (referenceId && referenceId != "" && referenceId != null && this.state.showLoader) {
      setTimeout(() => 
      Axios.post(GET_PRESCRIPTION_BY_REFERENCE, { referenceId })
        .then(response_data => {
          if (response_data.data.url_path != null && response_data.data.url_path != "") {
            Axios.post(GET_ARRAY_BUFFER_FORM_URL, { file_url: response_data.data.url_path }, { responseType: 'arraybuffer' }).then(async (response) => {
              const blob = new Blob([response.data], { type: 'application/pdf' });
              const url = URL.createObjectURL(blob);
              await this.setState({
                // rxPdfPath: "/pdfjs/web/viewer.html?file=" + url + "&print=true&vesrion=v"+Math.random(),
                rxPdfPath: url,
                // rxPdfPrint: true,
                showLoader: false
              },()=>
              {
                this.setState({ rxPdfPrint: true})
              }
              );
            });
          }
        }).catch(err => {
          //console.log(err);
        })
       , 1000);
    }

    let doctor_id = LS_SERVICE.get('nurse_doctor_id') || [];
    if (seniorDoctorId.length > 0) {
      doctor_id = LS_SERVICE.get('senior_doctor_id') || [];
    }
    let is_junior_doctor = this.state.is_junior_doctor.toString();

    var url;
    var config = LS_SERVICE.get('emr_server_config');
    var app_env = config?.app_env;
    var HOSPITAL_PORTAL_INDEPENDENT = config?.hospital_portal_independent || [];
    if((HOSPITAL_PORTAL_INDEPENDENT?.length > 0 && (HOSPITAL_PORTAL_INDEPENDENT.includes(parseInt(LS_SERVICE.get('slot_hospital_id'))) || HOSPITAL_PORTAL_INDEPENDENT.includes('All')))){
      url = SYNC_TOKEN_MHEA;
    }
    else{
      url = SYNC_TOKEN;
    }
    if (type == 'is_junior_doctor') {
      await API_SERVICE.post(url, { staff_id: LS_SERVICE.get('staff_id'), doctor_id: doctor_id, is_junior_doctor, queue_date, hospital_id: hospital_id });
      if(seniorDoctorId.length>0){
        let previous_date = moment(this.state.queue_date).subtract(1,'day').format('YYYY-MM-DD');
        let next_date = moment(this.state.queue_date).add(1,'day').format('YYYY-MM-DD');
        this.setState({
          previous_date: previous_date,
          next_date: next_date,
        })
      }
    }

    if (LS_SERVICE.get('user_type') == USERTYPES.nurse) {
      // if (doctor_id != null && doctor_id.length === 0) {
      //   return;
      // }
      // if(!doctor_id || doctor_id.length === 0) {
      //   return;
      // }

      postdata = { hospital_id: parseInt(hospital_id), doctor_id, queue_date };
      userType = 'nur';
    } else {
      if (LS_SERVICE.get('senior_doctor_id') != undefined && doctor_id.length > 0) {
        userType = 'doc';
        postdata = { hospital_id: hospital_id, doctor_id, is_junior_doctor, queue_date };
      } else {
        userType = 'doc';
        postdata = { hospital_id, queue_date };
      }
    }
    // wait for response only when Doctor is logged in for reasons of loading all data together as more than one API's are hitting
    if (!nurse_homecare) {
      // removed await as it was impacting other apis, first request of this api going to pending state
      this.getHomecareCards(this.state.homecare_page, this.state.per_page, this.state.activeTab, !this.state.firstLoad);
    }
    if (nurse_homecare) {
      if (this.state.isNurseLoggined) {
        this.setState({
          loading: false,
        });
      }
      return;
    }
    const patientQueueUri = userType === 'doc' ? API_SERVICE.post(PATIENT_QUEUE, postdata) : (LS_SERVICE.get('nurse_doctor_id') != null && postdata?.doctor_id?.length > 0 ? API_SERVICE.post(PATIENT_QUEUE, postdata) : null);
    if (LS_SERVICE.get('user_type') == USERTYPES.nurse) {
      if (this.state.nurseDoctors && this.state.nurseDoctors?.length > 0) {
        let result = this.state.nurseDoctors.map(a => a.id);
        var fetch_pending_patient = { hospital_id: parseInt(hospital_id), doctor_id: result, queue_date };
        if (!nurseDoctorId || nurseDoctorId == NaN || nurseDoctorId.length == 0) {
          fetch_pending_patient["selected_doctor"] = false;
        }
        var current_patient = await API_SERVICE.post(NURSE_CURRENT_PATIENT, fetch_pending_patient)
        this.setState({
          nurse_current_patient: current_patient?.data?.current_patients || []
        })
      }
    }
    // const patientHomecareQueueUri = API_SERVICE.get(HOMECARE_PATIENTLIST({ booking_status: 1, hospital_id, date: moment().format('YYYY-MM-DD'), page: homecare_currentpage, per_page }));
    // const patientHomecareCompletedQueueUri = API_SERVICE.get(HOMECARE_PATIENTLIST({ booking_status: 2, hospital_id, date: moment().format('YYYY-MM-DD'), page: homecare_currentpage, per_page }));

    // Homecare nurse don't need to hit norma promise api
    await Promise.all([patientQueueUri])
      .then(success => {
        const patientQueue = success[0];
        // const patientHomecareQueue = success[1];
        // const patientHomecareCompletedQueue = success[2];

        // if (typeof patientHomecareQueue.data === 'undefined') {

        // const { constants, pagination } = patientHomecareQueue;
        // const homecareVitals = constants ? (constants.vital_groups['VG003'] ? constants.vital_groups['VG003'].vital_sign : constants.vital_details) : null;

        // this.setState({
        // homecare_patients: patientHomecareQueue?.appointments,
        // homecare_upcoming_count: patientHomecareQueue?.filter_count?.upcoming,
        // homecare_completed_count: patientHomecareQueue?.filter_count?.completed,
        // constants,
        // homecareVitals,
        // homecare_currentpage: pagination?.current_page,
        // homecare_totaldata: pagination?.total,
        // homecare_totalpages: pagination?.total_pages,
        // })
        // }

        // if (typeof patientHomecareCompletedQueue.data === 'undefined') {

        //   const { constants, pagination } = patientHomecareCompletedQueue;
        // const homecareVitals = constants ? (constants.vital_groups['VG003'] ? constants.vital_groups['VG003'].vital_sign : constants.vital_details) : null;

        // this.setState({
        //   homecare_completed_count: patientHomecareCompletedQueue?.filter_count?.upcoming,
        // homecare_completed_patients: patientHomecareCompletedQueue?.appointments,
        // constants,
        // homecareVitals,
        // homecare_completed_currentpage: pagination?.current_page,
        // homecare_completed_totaldata: pagination?.total,
        // homecare_completed_totalpages: pagination?.total_pages,
        //   })
        // }

        if (typeof patientQueue === null || typeof patientQueue?.data === 'undefined') {
          this.setState({
            loading: false
          });
          return;
        }

        //Case 1 = IF booking _status = Pending && is_enable_mark_arrival =0 && isNurseLoggined = true then patient card should not be shown
        var allPateints = patientQueue.data.patients;
        if (this.state.isNurseLoggined) {
          allPateints.forEach((v, k) => {
            if (v.booking_status == "Pending" && this.checkDrForMarkArrival(v.doctor_id)) {
              delete allPateints[k];
            }
          });
        }

        this.setState({
          patients: allPateints,
          worklist_count: patientQueue.data?.worklist_count?.count,
          review_count: patientQueue?.data?.review_count?.count,
          pending_count: patientQueue?.data?.pending_count?.count,
          completed_count: patientQueue.data?.completed_count?.count,
          incomplete_tokens: patientQueue?.data?.incomplete_tokens
        }, () => {
          if (this.state.pending_count > 0 && this.state.goToWorklist && LS_SERVICE.get('user_type') == USERTYPES.doctor) {
            this.setState({
              isModalVisible: true
            });
          }
          this.setState({
            loading: false,
            showSpinner: false
          });
        });
        if (allPateints?.length > 0) {
          if (LS_SERVICE.get('user_type') == USERTYPES.nurse) {
            var waiting_patients = [];
            waiting_patients = allPateints.filter(x => x.patient_forwarded_to == '2' && x.source == 'qms' && x.patient_nursing_status == 1);
            var onhold_patients = [];
            var calledPatients = allPateints.filter(x => x.patient_forwarded_to == '2' && x.source == 'qms' && x.patient_nursing_status == 2);
            var skippedPatients = allPateints.filter(x => x.patient_forwarded_to == '2' && x.source == 'qms' && x.patient_nursing_status == 3);
            var onhold_patients = calledPatients.concat(skippedPatients);
            this.setState({
              nurse_onhold_patients: onhold_patients,
              nurse_waiting_patients: waiting_patients
            })
          }
          else {
            var currentPatient = allPateints.find(x => x.visit_status == 3);
            if (currentPatient) {
              this.setState({
                current_patient: currentPatient
              })
            }
          }
        }





        // if(pdfReferenceId != undefined && pdfReferenceId != null && allPateints.length > 0){
        //   let cardDeails = allPateints.filter((row) => row.booking_id == pdfReferenceId);
        //   if(cardDeails.length > 0){
        //     cardDeails.map((patient) => {
        //       console.log(patient)
        //       if(patient.visit_pdf != null && patient.visit_pdf != ""){
        //         let isVcOpd = '';
        //         if(patient.consult_type == 1){
        //           isVcOpd = true;
        //         }
        //         LS_SERVICE.set('stopPrinting', isVcOpd);

        //         await Axios.post(GET_ARRAY_BUFFER_FORM_URL, { file_url : patient.visit_pdf }, {responseType: 'arraybuffer'}).then((response) => {
        //           const blob = new Blob( [ response.data ], {type: 'application/pdf'});
        //           const url = URL.createObjectURL( blob );
        //           this.setState({
        //             rxPdfPath: "/pdfjs/web/viewer.html?file=" + url + "&print=true&vesrion=v"+Math.random(),
        //             showLoader: false
        //           });
        //         });
        //       }
        //     });
        //   }
        // }

        return;
      })

    // if (typeof patientQueue.data === 'undefined') {
    //   this.setState({
    //     loading: false
    //   });
    //   // notification['error']({
    //   //   description: "Not able to fetch worklist information!"
    //   // });
    //   return;
    // }

    // this.setState({
    //   patients: patientQueue.data.patients,
    //   worklist_count: patientQueue.data.worklist_count.count,
    //   pending_count: patientQueue.data.pending_count.count,
    //   completed_count: patientQueue.data.completed_count.count,
    //   incomplete_tokens: patientQueue.data.incomplete_tokens
    // }, () => {
    //   if (this.state.pending_count > 0 && this.state.goToWorklist && LS_SERVICE.get('user_type') == USERTYPES.doctor) {
    //     this.setState({
    //       isModalVisible: true
    //     });
    //   }
    //   this.setState({
    //     loading: false
    //   });
    // });

    // if (patientHomecareQueue) {
    //   debugger;
    // }
    // return;
  }

  async initSoapList() {
    // const arrRoute = this.props.location.pathname.split('/');
    // const hid = arrRoute[arrRoute.length - 1];
    const { match: { params } } = this.props;
    const { id } = params;
    const soapList = await API_SERVICE.post(SOAP_CONFIGURATION_LIST, {
      hospital_id: id,
      staff_id: LS_SERVICE.get('staff_id'),
      speciality_id: LS_SERVICE.get('speciality_id') || null
    });

    LS_SERVICE.set('soap_conf_list', soapList);
    LS_SERVICE.set('soap_conf_list_' + id, soapList);

    const pageList = [];
    const newList = [];

    const newSoapList = Object.values(soapList).map((data) => {
      return data.sort((a, b) => a.display_ordering - b.display_ordering)
    });

    Object.values(newSoapList).flat().forEach((en) => {
      if (en.display === 'Y') {
        if (!pageList.includes((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name)) {
          pageList.push((en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name);
          newList.push({
            'previous_url': en.alternate_url_format,
            'url': en.alternate_url_format,
            'parent_module': en.parent_module,
            'module': (en.parent_module === 'subjective' || en.parent_module === 'objective') ? en.parent_module : en.name
          })
        }
      }
    });

    if (newList.length > 0) {
      this.setState({
        goToSoapPatientUrl: newList[0].url
      }, () => {
        LS_SERVICE.set('goToSoapPatientUrl', newList);
        LS_SERVICE.set('goToSoapPatientPageList', pageList);
      });
    }
    return;
  }

  async nurseMarkArrivedHandler(e, bookingId) {
    e.stopPropagation();
    const booking_id = bookingId;
    if (bookingId) {
      Axios.post(NURSE_MARK_ARRIVAL, { booking_id })
        .then(success => {
          notification.success({
            message: 'Successfully marked arrived',
            placement: 'topRight'
          });
          this.setState({ patients: [] });
          // this.loadPatientQueue();
          this.initQueue();
        }).catch(err => {
          notification.error({
            message: 'Failed to marke arrived',
            placement: 'topRight'
          });
          //console.log(err);
        })
    }
  }

  makeVitalsData = (visitVitals) => {
    let vitalDetails = [];

    // let { patientHeight, patientWeight } = this.state;

    let vital_data = {}

    this.state.homecareVitals.map((v, k) => {
      vitalDetails[v.id] = [];
      vital_data[v.id] = null;
    });

    // vitalDetails['date'] = [];

    if (Object.keys(visitVitals).length > 0) {
      // let index = 0;
      let index2 = 0;
      // if (index < 1) {
      visitVitals.map((vV, k) => {
        // debugger;
        if (index2 < 1) {
          if(vV){
          Object.keys(vV).forEach(j => {
            if (vitalDetails[j]) vitalDetails[j] = vV[j];
            // if (j == 'V001' && patientHeight == null && patient.age > HOSPITALDEFAULTS.age && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientHeight = temp_vitals[j];
            // if (j == 'V002' && patientWeight == null && patient.age > HOSPITALDEFAULTS.age && moment().diff(moment(temp_vitals.date[j]), 'months') < 2 && temp_vitals[j]) patientWeight = temp_vitals[j];
          });
          }
          index2++
        }
        // index++;
      });
      // }
    }

    let vitals_data = {};
    let newVitalEmptyArray = vitalDetails['date'] ? Array.from({ length: 1 }, i => '') : [];
    this.state.homecareVitals.map((v, k) => {
      vitals_data[v.id] = vitalDetails[v.id] && vitalDetails[v.id] != null && vitalDetails[v.id] != '' ? vitalDetails[v.id] : '';
    });
    // vitals_data.date = vitalDetails['date'];

    //vitals groups
    // let vitalGroupData = [];
    // const doctorSpeciality = LS_SERVICE.get('speciality_id');
    // let allVital = null;
    // for (const vitalGroup of Object.values(this.state.constants.vital_groups)) {
    //   if (vitalGroup.speciality_id == doctorSpeciality) {
    //     var vitalSignIds = [];
    //     for (const vitalSign of Object.values(vitalGroup.vital_sign)) {
    //       vitalSignIds.push(vitalSign.vital_id);
    //     }
    //     vitalGroup.vitalSignIds = vitalSignIds.toString().replace(/(^,)|(,$)/g, "");
    //     vitalGroupData.push(vitalGroup);
    //   }
    //   if (vitalGroup.id == 1) {
    //     var vitalSignIds2 = Object.values(vitalGroup.vital_sign).map(vs => vs.vital_id);
    //     vitalGroup.vitalSignIds = vitalSignIds2.toString().replace(/(^,)|(,$)/g, "");
    //     allVital = vitalGroup;
    //   }
    // }
    // if (vitalGroupData.length == 0)
    //   vitalGroupData.push(allVital);

    return vitals_data;

    // this.setState({
    //   selectedVitalGroups: vitalGroupData,
    //   vitalsType: vitalGroupData[0].id,
    //   displayVitals: vitalGroupData[0].vitalSignIds.split(','),
    //   patientHeight,
    //   patientWeight,
    //   ...vital_data,
    //   V001: patientHeight,
    //   V002: patientWeight,
    //   visitVitalsFormatted: vitals_data,
    //   vitals_data_formatted: true
    // })
  }

  async setHospitalToStorage() {
    const { hospital_id } = this.state;
    // LS_SERVICE.delete('slot_hospital_id');
    LS_SERVICE.set('slot_hospital_id', hospital_id);
    const speciality_id = LS_SERVICE.get('speciality_id');
    const staff_id = LS_SERVICE.get('staff_id');

    const soapType = await Axios.post(SOAP_TYPE_FETCH, {
      staff_id: staff_id && staff_id != null ? parseInt(staff_id) : null,
      speciality_id: speciality_id && speciality_id != null ? parseInt(speciality_id) : null,
      hospital_id: hospital_id && hospital_id != null ? parseInt(hospital_id) : null,
      source: 'doctor',
      setting_for: 'doctor',
    });
    if (soapType.data && soapType.data.selected_soap && soapType.data.selected_soap.type) {
      LS_SERVICE.set('soap_type', soapType.data.selected_soap.type);
    }
    else {
      LS_SERVICE.set('soap_type', 'single');
    }

    var ss_configuration_ls = LS_SERVICE.get('ss_configuration');
    if(!ss_configuration_ls){
      const ss_configuration = await Axios.post(SINGLE_SCREEN_CONFIGURATION_FETCH, {
        staff_id: staff_id && staff_id != null ? parseInt(staff_id) : null,
        speciality_id: speciality_id && speciality_id != null ? parseInt(speciality_id) : null,
        hospital_id: hospital_id && hospital_id != null ? parseInt(hospital_id) : null,
        source: "doctor",
        setting_for: "doctor"
      });
      if (ss_configuration.data && ss_configuration.data.ss_configuration) {
        LS_SERVICE.set('ss_configuration', ss_configuration.data.ss_configuration);
      }
    }

    let hospital = LS_SERVICE.get('slot_hospitals')?.filter((hosp) => hosp.id == hospital_id);
    LS_SERVICE.set('slot_hospital_master', hospital?.[0]?.['own_master'] ?? 'N');
    headerService$.setHospital({ setHospital: hospital_id });
  }

  loader() {
    return (
      <div className="col-md-12 text-center">{LOADER_RED}</div>
    )
  }

  getSeparator(value) {
    if (value && value != null) {
      return ' | ';
    } else {
      return null;
    }
  }

  patientInfo(patient) {
    return (
      <>
        {patient.uhid || patient.age || patient.gender || patient.blood_group ? <span> - </span> : null}
        {patient.uhid ? <span>{(MYHC_APPTYPE === MHVCMARKETPLACE || MYHC_APPTYPE === MHEA) ? "" : "UHID"} {patient.uhid}</span> : null}
        {patient.dob ? <span>{this.getSeparator(patient.uhid)} {this.getPatientAge(patient.dob)}</span> : null}
        {patient.gender ? <span>{this.getSeparator(patient.dob)} {patient.gender}</span> : null}
        {patient.mobile_no ? <span>{this.getSeparator(patient.gender)} Mobile No.: {patient.mobile_no}</span> : null}
        {patient.blood_group ? <span>{this.getSeparator(patient.mobile_no)} Blood Group: {patient.blood_group}</span> : null}
        {this.state.isNurseLoggined && patient.doctor_name ? <span style={{ marginLeft: "20px", fontWeight: "bold" }}> Dr. {patient.doctor_name}</span> : null}

        {patient.payor_name ? <span>{this.getSeparator(patient.payor_name)} Payor Name: {patient.payor_name}</span> : null}
        {patient.package_name ? <span>{this.getSeparator(patient.package_name)} Package Name: {patient.package_name}</span> : null}
      </>
    )
  }

  appointmentTime(patient) {
    const appointment_type = patient.is_walk_in ? patient.is_walk_in : 'Unknown';
    return patient.start_time;
  }

  holdInfo(patient) {
    const currentUserRole = LS_SERVICE.get('user_type');
    const practicing = this.state.practicing;
    const is_qms_enabled = this.state.is_qms_enabled;

    LS_SERVICE.set('allow_hours', this.state.allow_hours);
    LS_SERVICE.set('pres_modification', this.state.pres_modification);
    LS_SERVICE.set('updated_at', patient.updated_at);

    // console.log("patient.booking_status: ", patient.booking_status);

    // console.log("checkDrForMarkArrival: ", this.checkDrForMarkArrival(patient.doctor_id));
    var appointmentCompletedTime = LS_SERVICE.get('appointmentCompletedTime') ?? {};
    if (patient.visit_status == 1) {
      appointmentCompletedTime[patient.qms_token_id] = patient.consult_end_at;
    }
    LS_SERVICE.set('appointmentCompletedTime', appointmentCompletedTime);

    if (patient.booking_status == "Pending" && this.checkDrForMarkArrival(patient.doctor_id) && currentUserRole == USERTYPES.nurse) {
      // if (patient.booking_status == "Pending" && patient.is_enable_mark_arrival === 1 &&  currentUserRole == USERTYPES.nurse) {
      return (
        <div className="patient_cta">
          <button type="button" onClick={(e) => this.nurseMarkArrivedHandler(e, patient.booking_id)} className="btn btn-sm btn-outline-danger text-uppercase">Mark Arrival</button>
        </div>
      )
    }
    else if (patient.visit_status !== 2 && patient.list === 'worklist') {
      return (
        <div className="patient_cta" style={{ top: '0px' }}>
          <div className="">
            {/* {
              patient.visit_status === 3 && patient.source == 'qms'
            ?
              <Tooltip placement="topLeft" title="Skip and Call Next Patient">
                <button onClick={() => this.skipCallNextAppointment(patient)} className="btn border-0 px-2"><img src={playIcon} className="card_icons"/></button>
              </Tooltip>
            : 
              null
            } */}
            {/* {
              patient.source == 'qms'
            ?
              <Tooltip placement="topLeft" title="Cancel Consult" arrowPointAtCenter>
                <button onClick={() => this.cancelAppointment(patient)} className="btn border-0 px-2"><img src={cancelIcon} className="card_icons"/></button>
              </Tooltip>
            : 
              null
            } */}
          </div>
          <div className="col-12 pr-0">
            {
              practicing && practicing !== null && practicing.status
                ?
                <>
                  {
                    patient.source == 'qms'
                      ?
                      <button onClick={(this.state.queue_date > moment().format('YYYY-MM-DD')) ? false : () => ISALLOWEDITSOAP(this.state.pres_modification, this.state.allow_hours, appointmentCompletedTime[patient.qms_token_id], 'patient-queue') ? this.callNextQMS(patient) : null} type="button" className="btn btn-sm btn-primary text-uppercase px-2 py-1">
                        CALL
                      </button>
                      :
                      <button onClick={(this.state.queue_date > moment().format('YYYY-MM-DD')) ? false : () => ISALLOWEDITSOAP(this.state.pres_modification, this.state.allow_hours, appointmentCompletedTime[patient.qms_token_id], 'patient-queue') ? this.callNext(patient) : null} type="button" className="btn btn-sm btn-primary text-uppercase px-2 py-1">
                        {(is_qms_enabled) ? "CALL" : APPS_FEATURES_LIST.call_start_consult_label}
                      </button>
                  }
                </>
                :
                <>
                  {
                    currentUserRole == USERTYPES.nurse && patient.source == 'qms' && patient.patient_forwarded_to == '2'
                      ?
                      <>
                        {
                          (patient.patient_nursing_status == 2 || patient.patient_nursing_status == 3) && patient.patient_forwarded_by === 0
                            ?
                            <>
                              <Tooltip placement="topLeft" title="Recall">
                                <button onClick={() => this.callNextQMSNurse(patient, 'call')} type="button" style={{ display: this.state.forworedTokenIds.includes(patient.token_id) ? 'none' : '' }} className={this.state.skipTokenIds.includes(patient.token_id) ? "btn btn-sm btn-primary text-uppercase px-2 py-1" : "btn border-0 px-2"}>{this.state.skipTokenIds.includes(patient.token_id) ? "CALL" : <img src={syncIcon} className="card_icons" />}</button>
                              </Tooltip>
                              <Tooltip placement="topLeft" title="Skip">
                                <button style={{ display: this.state.skipTokenIds.includes(patient.token_id) ? 'none' : '' }} onClick={() => this.callNextQMSNurse(patient, 'skip')} className="btn border-0 px-2"><img src={playIcon} className="card_icons" /></button>
                              </Tooltip>
                              <Tooltip placement="topLeft" title="Forward To Doctor">
                                <button onClick={() => this.callNextQMSNurse(patient, 'forward')} style={{ display: this.state.forworedTokenIds.includes(patient.token_id) ? 'none' : '' }} className="btn border-0 px-2"><img src={doctorIcon} className="card_icons" /></button>
                              </Tooltip>
                            </>
                            :
                            <>
                              {
                                (patient.patient_forwarded_by === null || patient.patient_forwarded_by === 0) && (patient.patient_nursing_status == 3 || patient.patient_nursing_status == 1 || patient.patient_nursing_status == 'NA')
                                  ? <>
                                    <Tooltip placement="topLeft" title="Call">
                                      <button onClick={() => this.callNextQMSNurse(patient, 'call')} type="button" style={{ display: this.state.forworedTokenIds.includes(patient.token_id) ? 'none' : '' }} className="btn btn-sm btn-primary text-uppercase px-2 py-1">
                                        CALL
                                      </button>
                                    </Tooltip>
                                    <Tooltip placement="topLeft" title="Forward To Doctor">
                                      <button onClick={() => this.callNextQMSNurse(patient, 'forward')} style={{ display: this.state.forworedTokenIds.includes(patient.token_id) ? 'none' : '' }} className="btn border-0 px-2"><img src={doctorIcon} className="card_icons" /></button>
                                    </Tooltip>
                                  </>
                                  :
                                  null
                              }
                            </>
                        }
                      </>
                      :
                      null
                  }
                </>
            }
          </div>
        </div>
      )
      // }
      // else if (patient.visit_status === 3 && patient.list === 'worklist') {
      //   return (
      //     <div className="patient_cta">
      //       {practicing && practicing !== null && practicing.status ? 
      //       // <button type="button" className="btn btn-sm btn-primary text-uppercase">
      //       //   {APPS_FEATURES_LIST.call_start_consult_label}
      //       // </button>
      //       <>
      //       {/* <button className="btn border-0 px-2" href="#"><span className="arrows-refresh-2847"></span></button> */}
      //       <button onClick={() => this.reCallAppointment(patient)} className="btn border-0 px-2"><img src={syncIcon} className="card_icons"/></button>
      //       <button onClick={() => this.cancelAppointment(patient)} className="btn border-0 px-2"><img src={cancelIcon} className="card_icons"/></button>
      //       <button onClick={() => this.skipCallNextAppointment(patient)} className="btn border-0 px-2"><img src={playIcon} className="card_icons"/></button>
      //       </>
      //        : null}
      //     </div>
      //   )
    } else if (patient.visit_status === 2 && patient.list === 'worklist') {
      return (
        <div className="patient_cta" style={{ top: '0' }}>
          <div className="col-12 mb-2 ml-2">
            {
              patient.source == 'qms' && currentUserRole == USERTYPES.doctor && practicing && practicing !== null && practicing.status
                ?
                <button type="button" className="btn btn-sm btn-primary text-uppercase px-2 py-1 pr-0" onClick={() => this.callNextQMS(patient)}>RE-CALL</button>
                :
                null
            }
          </div>
          {
            patient.source != 'qms'
              ?
              <div className="col-12">
                {practicing && practicing !== null && practicing.status ? <button onClick={(this.state.queue_date > moment().format('YYYY-MM-DD')) ? false : () => ISALLOWEDITSOAP(this.state.pres_modification, this.state.allow_hours, appointmentCompletedTime[patient.qms_token_id], 'patient-queue') ? this.callNext(patient) : null} type="button" className="btn btn-sm btn-primary text-uppercase px-2 py-1">
                  {is_qms_enabled ? "CALL" : APPS_FEATURES_LIST.call_label}
                </button> : null}
              </div>
              :
              null
          }
        </div>
      )
    } else if (practicing !== null && practicing?.status && patient.list === 'pending' && currentUserRole == USERTYPES.doctor) {
      return (
        <div className="patient_cta" style={{ top: '0' }}>
          <button type="button" className="btn btn-sm btn-primary text-uppercase px-2 py-1">COMPLETE</button>
        </div>
      )
    } else if (patient.list === 'completed' && currentUserRole == USERTYPES.doctor) {
      return (
        <div className="patient_cta">
          {practicing && practicing !== null && practicing?.status && ISALLOWEDITSOAP(this.state.pres_modification, this.state.allow_hours, patient.consult_end_at, 'patient-queue') ? <><button type="button" className="btn btn-sm btn-primary btn-rx mb-2 text-uppercase">EDIT RX</button><br /></> : null}
          {patient.visit_pdf ? <button onClick={(e) => this.viewRx(e, patient.visit_pdf)} type="button" className="btn btn-sm btn-primary btn-rx text-uppercase show-rx-modal" >VIEW RX</button> : null}
        </div>
      )
    } else if (patient.list === 'completed' && currentUserRole == USERTYPES.nurse) {
      return (
        <>
          <div className="patient_cta">
            <button onClick={(e) => this.nursePatientRevisit(e, patient)} type="button" className="btn btn-sm btn-primary btn-rx mb-2 text-uppercase">EDIT RX</button>
            {patient.visit_pdf ?
              <><br /><button onClick={(e) => this.viewRx(e, patient.visit_pdf)} type="button" className="btn btn-sm btn-primary btn-rx text-uppercase show-rx-modal" >VIEW RX</button></>
              : null}
          </div>
        </>
      )
    } else if (patient.list === 'review' && currentUserRole == USERTYPES.doctor) {
      return (
        <div className="patient_cta">
          {patient.visit_pdf ? <><button onClick={(e) => this.viewRx(e, patient.visit_pdf)} type="button" className="btn btn-sm btn-primary btn-rx mb-2 text-uppercase" >VIEW RX</button><br /> </> : null}
          {
            patient.source == 'qms'
              ?
              <button onClick={(this.state.queue_date > moment().format('YYYY-MM-DD')) ? false : () => ISALLOWEDITSOAP(this.state.pres_modification, this.state.allow_hours, appointmentCompletedTime[patient.qms_token_id], 'patient-queue') ? this.callNextQMS(patient) : null} type="button" className="btn btn-sm btn-primary btn-rx text-uppercase show-rx-modal">
                CALL
              </button>
              : null}
        </div>
      )
    }
    return (
      null
    )
  }

  viewRx(e, pdf) {
    this.setState({
      showRxModal: true,
      rxPdfPath: pdf
    });
    e.stopPropagation();
  }

  progressBar(patient, type) {
    const appointment_type = patient.is_walk_in ? patient.is_walk_in : 'Unknown';

    if (type === 'appointment') {
      return (appointment_type === 'Walk In') ? '' : 'completed';
    } else if (type === 'arrived') {
      return (patient.token_time && patient.token_time != null) ? 'completed' : '';
      // return (appointment_type === 'Walk In') ? 'completed first' : 'completed';
      //let t1 = (appointment_type === 'Walk In') ? 'first' : '';
      //let t2 = t1 + ' ' + (patient.token_time && patient.token_time != null) ? 'completed' : '';
      //return t2;
    } else if (type === 'vital') {
      let vital_completed = (patient.vital_set) ? "completed" : '';
      if (patient.is_completed && patient.is_completed_time) {
        if (!vital_completed) {
          vital_completed = (patient.is_doctor_checkout == '1') ? "completed" : "completed escape";
        }
      }
      return vital_completed;
    } else if (type === 'visit') {
      return (patient.is_completed) ? "completed" : '';
    }
    return '';
  }

  onPubNubChatClickStateLife = (e, patient) => {
    e.stopPropagation()
    const data = {
      id: patient.booking_id,
      patient
    }
    if (patient !== undefined) {
      pubnubService$.pubNubBooking_data({ data: { ...data }, show: true, alert: true });
    }
  }

  completedVcAppointment(patient) {
    const currentUserRole = LS_SERVICE.get('user_type');
    const practicing = this.state.practicing;
    if (currentUserRole == USERTYPES.doctor) {
      return <div className="patient_cta mr-2">
        {practicing !== null && practicing?.status && ISALLOWEDITSOAP(this.state.pres_modification, this.state.allow_hours, patient.consult_end_at, 'patient-queue') ? <><button type="button" className="btn btn-sm btn-primary btn-rx mb-2 mt-2 text-uppercase">EDIT RX</button><br /></> : null}
        {patient.visit_pdf ? <button onClick={(e) => this.viewRx(e, patient.visit_pdf)} type="button" className="btn btn-sm btn-primary btn-rx text-uppercase show-rx-modal" >VIEW RX</button> : null}
      </div>;
    } else {
      return <div className="patient_cta mr-2">
        {patient.visit_pdf ?
        <button onClick={(e) => this.viewRx(e, patient.visit_pdf)} type="button" className="btn btn-sm btn-primary text-uppercase show-rx-modal px-2 py-1" >VIEW RX</button>:
        null}
      </div>
    }
  }

  showVitals(value) {
    let isShow = true;

    if (value.patient_vitals !== undefined && value.patient_vitals !== null) {
      isShow = value.patient_vitals != null && Object.keys(value.patient_vitals).length > 0 ? true : false
    }

    return (<div className={`text-center latest-vital ${isShow ? "visible" : "hidden"}`}>
      <span>Latest Vitals:</span>{' '}
      <span className="theme-text mb-0">
        {isShow && (
          moment(value.patient_vitals_latest_date).format('D MMM Y | h:mm A')
        )}
      </span>
    </div>);
  }

  patientVitalCustom(value, key) {
    if (key !== undefined && key !== null && value.patient_vitals_custom !== undefined && value.patient_vitals_custom !== null) {
      if (value.patient_vitals_custom[key] !== undefined && value.patient_vitals_custom[key] !== null) {
        return true;
      }
    }
    return false;
  }
  async checkOutAppointment(e, patient) {
    e.stopPropagation();
    
    let { disabledCheckoutBookings } = this.state;
    try {
      await Axios.post(PATIENT_CHECKOUT, {
        booking_id: patient.booking_id
      });
      this.loadPatientQueue();
      disabledCheckoutBookings.push(patient.booking_id);
      this.setState({
        disabledCheckoutBookings: disabledCheckoutBookings
      });
      //this.props.history.push('/patient-queue/' + patient.hospital_id);
    } catch (error) {
      disabledCheckoutBookings.splice(
        disabledCheckoutBookings.indexOf(patient.booking_id), 1
      );
      this.setState({
        disabledCheckoutBookings: disabledCheckoutBookings
      });
      notification.destroy();
      notification.error({
        description: 'Something went wrong, checkout failed, try again!'
      });
    }
  }

  handlePageScroll = () => {
    const { activeTab, worklist_count, completed_count, pending_count } = this.state;
    if (this.scrollTimer != null) {
      clearTimeout(this.scrollTimer);
    }
    let totalCount = null;
    if (activeTab == 'worklist') {
      totalCount = worklist_count;
    }
    if (activeTab == 'completed') {
      totalCount = completed_count;
    }
    if (activeTab == 'pending') {
      totalCount = pending_count;
    }


    this.scrollTimer = setTimeout(() => {
      const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
      let sendRequest = false;
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        sendRequest = true;
      }

      if (sendRequest && !this.isLoadingOnScroll && typeof this.cardsLimitScroll[activeTab] != 'undefined') {
        this.isLoadingOnScroll = true;
        const oldLimit = this.cardsLimitScroll[activeTab]['upperLimit'];
        const limitToAdd = oldLimit + 10;
        const newLimit = (totalCount != null && totalCount != 0 && limitToAdd > totalCount) ? totalCount : limitToAdd;
        this.cardsLimitScroll[activeTab]['upperLimit'] = newLimit;
        this.setState({ reRender: true }, () => { this.isLoadingOnScroll = false; });
      }
    }, 150);
  }

  patientCards(type, pageScrolled) {
    let { hospital_id, disabledCheckoutBookings, worklist_count, search_keyword } = this.state;
    search_keyword = search_keyword.toLowerCase();
    const currentUserRole = LS_SERVICE.get('user_type');
    const practicing = this.state.practicing;

    const todaysDate = moment().format('YYYY-MM-DD');
    if (!type.includes('homecare')) {
      let patients = [];
      if (this.state.patients.length > 0) {
        patients = this.state.patients.filter((fpatient) => {
          
          if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
            if (type === 'completed' && fpatient.list !== 'worklist' && fpatient.list !== 'pending' && fpatient.list !== 'review') {
              return fpatient;
            } else if (type === 'worklist' && fpatient.list === 'worklist') {
              return fpatient;
            } else if (type === 'pending' && fpatient.list === 'pending') {
              return fpatient;
            } else if (type === 'review' && fpatient.list === 'review') {
              return fpatient;
            }
          }
          //For nurse role 
          // if (fpatient.consult_type == 1 && LS_SERVICE.get('user_type') == USERTYPES.nurse) {
          //   if (type === 'completed' && fpatient.list !== 'worklist' && fpatient.list !== 'pending') {
          //     return fpatient;
          //   } else if (type === 'worklist' && fpatient.list === 'worklist') {
          //     return fpatient;
          //   }
          // }

          if (LS_SERVICE.get('user_type') == USERTYPES.nurse) {
            if (type === 'completed' && fpatient.list !== 'worklist' && fpatient.list !== 'pending') {
              return fpatient;
            } else if (type === 'worklist' && fpatient.list === 'worklist') {
              return fpatient;
            }
          }
        });

        if (patients.length > 0 && search_keyword != '') {
          patients = patients.filter((fpatient) => {
            const pname = fpatient?.fullname?.toLowerCase();
            const uhid = fpatient?.uhid?.toLowerCase();
            if (pname?.includes(search_keyword)) {
              return fpatient;
            } else if (typeof fpatient?.mobile_no != 'null' && fpatient?.mobile_no?.includes(search_keyword)) {
              return fpatient;
            } else if (typeof uhid != 'undefined' && uhid?.includes(search_keyword)) {
              return fpatient;
            } else {
              patients = [];
            }
          });
        }

        if (typeof this.cardsLimitScroll[type] != 'undefined') {
          patients = patients.slice(0, this.cardsLimitScroll[type]['upperLimit']);
          this.cardsLimitScroll[type]['inListCount'] = patients.length;
        }
      }
      var appointmentCompletedTime = LS_SERVICE.get('appointmentCompletedTime') ?? {};
      patients.map((patient, index) => {
        if (patient.visit_status == 1)
          appointmentCompletedTime[patient.qms_token_id] = patient.consult_end_at;
      }, appointmentCompletedTime)
      
      LS_SERVICE.set('appointmentCompletedTime', appointmentCompletedTime); return (
        <>
          {patients.length > 0 ?
            
            patients.map((patient, index) => (
              // <div key={index} className={"card mb-4 patient-card rounded-0 " + (patient.consult_type == 1 ? ' virtual_consult' : '') + (patient.visit_status === 3 && patient.list === 'worklist' ? ' emeregency' : '')}>
              <div key={index} className={"card mb-3 patient-card rounded-0 " + (patient.visit_status === 3 && patient.list === 'worklist' ? 'emergency_opd_consult' : (patient.consult_type == 1 ? ' virtual_consult' : 'opd_consult'))}>
                <div
                  className=""
                  onClick={(this.state.queue_date > moment().format('YYYY-MM-DD') && patient.consult_type == 2) ? false : () => patient.list =='worklist' ? ISALLOWEDITSOAP(this.state.pres_modification, this.state.allow_hours, appointmentCompletedTime[patient.qms_token_id], 'patient-queue')  && ((patient.list === 'worklist' && patient.source == 'default') || (patient.list != 'worklist' && patient.list != 'review')) ? this.callNext(patient) : null: ((patient.list === 'worklist' && patient.source == 'default') || (patient.list != 'worklist' && patient.list != 'review')) ? this.callNext(patient) : null}>
                  {/* onClick={() => this.enterCard(patient,index)} */}
                  {/* <div className=""> */}
                  <div className="card-body card-body p-0  pb-0">
                    <div className='row'>
                      <div className='col-12'>
                        <div className='card new-patient-card py-2 mx-3 px-0' style={{ borderRadius: '0px' }}>
                          <div className='row'>
                            <div className='col-12'>
                              <div className='row'>
                                <div className='col-4'>
                                  <span className="text-dark "><span className='font-weight-bold'>{patient.title_name != null ? patient.title_name + " " + patient.fullname : patient.fullname}</span>&nbsp;&nbsp;{this.getPatientAge(patient.dob)}({patient.gender ? patient.gender == "Male" ? "M" : "F" : ""})&nbsp; &nbsp;<span className='font-weight-bold'>{patient.uhid ? patient.uhid : ""}</span> </span>
                                </div>
                                <div className='col'>
                                  <span className='text-muteds' style={{ color: '#5c5c5c' }}>&nbsp;Mobile&nbsp;</span>
                                  <span className='text-dark font-weight-bold mr-2'>{patient.mobile_no && patient.mobile_no!= '' && patient.mobile_no != null ? patient.mobile_no : '--'}</span>
                                  <span className='text-muteds' style={{ color: '#5c5c5c', paddingLeft: '20px' }}>Payor&nbsp;</span>
                                  <span className='text-dark font-weight-bold text-uppercase mr-2'>{patient.payor_name ? patient.payor_name : "--"}</span>
                                </div>
                                {
                                  currentUserRole == USERTYPES.nurse
                                ?
                                  <div className='col-auto text-right'>
                                    <span style={{float:'right'}} className='text-dark font-weight-bold'>Dr. {patient.doctor_name}</span>
                                  </div>
                                :
                                  null
                                }
                              </div>
                            </div>
                            {/* <div className='col-4'>
                              <span style={{float:'right'}} className="text-danger">Hip Replacement Surgery</span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"row mx-0 p-2 mt-0" + (patient.visit_status === 3 && patient.list === 'worklist' ? ' emeregency' : (patient.consult_type == 1 ? ' virtual_consult' : ''))}>

{/* 
                      {
                          patient.source == 'qms'
                          ?
                          <div className={`col-${todaysDate == patient.token_date && patient.list === 'worklist' && (practicing !== null && practicing?.status || LS_SERVICE.get('user_type') == USERTYPES.nurse) && patient.consult_type !== 1 && (patient.source == 'qms' || CHECKOUT_HOSPITALS_ENABLED.includes(hospital_id)) ? 6 : 8}`}>
                            <span className="text-dark font-weight-bold">{patient.title_name!=null ? patient.title_name + " " + patient.fullname : patient.fullname}</span>
                            {this.patientInfo(patient)}
                          </div>
                          :
                            <div className={`col-${todaysDate == patient.token_date && patient.list === 'worklist' && (practicing !== null && practicing?.status || LS_SERVICE.get('user_type') == USERTYPES.nurse) && (patient.source == 'default' || CHECKOUT_HOSPITALS_ENABLED.includes(hospital_id)) ? 6 : 8}`}>
                            <span className="text-dark font-weight-bold">{patient.title_name!=null ? patient.title_name + " " + patient.fullname : patient.fullname}</span>
                            {this.patientInfo(patient)}
                          </div>
                      } */}
                      {/* <div className="col-3">
                        {patient.list === 'worklist' && patient.consult_type !== 1 && APPS_FEATURES_LIST.show_opdconsult_flag_on_queue ? <label className="virtual_consult_icon"><img src="/assets/images/ic-opd.svg" width="18px" className="mr-2" style={{position:'relative', top:'-2px' }} />OPD CONSULT</label> : null}
                        {patient.consult_type === 1 ? <label className="virtual_consult_icon text-secondary"><i className="icon_video"></i>VIRTUAL CONSULT</label> : null}
                      </div> */}
                      <div className='col-4'>
                        <div className='d-flex'>
                          {
                            patient.on_oxygen != null && patient.on_oxygen == 'Y' ?
                              <div className='mr-3'>
                                <Tooltip placement="topLeft" title={ 'Oxygen' }>
                                  <img src={oxygenIcon} className="card_icons" />
                                </Tooltip>
                              </div>
                              : null
                          }
                          {
                            patient.on_stretcher != null && patient.on_stretcher == 'Y' ?
                              <div className='mr-3'>
                                <Tooltip placement="topLeft" title={ 'Stretcher' }>
                                  <img src={stretcherIcon} className="card_icons" />
                                </Tooltip>
                              </div>
                              : null
                          }
                          {
                            patient.on_wheelchair != null && patient.on_wheelchair == 'Y' ?
                              <div className='mr-3'>
                                <Tooltip placement="topLeft" title={ 'Wheelchair' }>
                                  <img src={wheelchairIcon} className="card_icons" />
                                </Tooltip>
                              </div>
                              : null
                          }
                          {
                            patient.remarks != null && patient.remarks != ''?
                              <div className='mr-3'>
                                <Tooltip placement="topLeft" title={patient.remarks !== null ? patient.remarks : 'Remarks'}>
                                  <img src={commentIcon} className="card_icons" />
                                </Tooltip>
                              </div>
                              : <div className='mr-3'>&nbsp;</div>
                          }
                          {
                            patient.on_hwc != null && patient.on_hwc == 'Y' ?
                              <div className='mr-3'>
                                <Tooltip placement="topLeft" title={patient.on_hwc_comment !== null ? patient.on_hwc_comment : 'HWC'}>
                                  <img src={hwcIcon} className="card_icons" />
                                </Tooltip>
                              </div>
                              : null
                          }
                          {
                            patient.on_vip != null && patient.on_vip == 'Y' ?
                              <div className='mr-3'>
                                <Tooltip placement="topLeft" title={patient.on_vip_comment !== null ? patient.on_vip_comment : 'VIP'}>
                                  <img src={vipIcon} className="card_icons" />
                                </Tooltip>
                              </div>
                              : null
                          }
                          {
                            patient.on_vulnarable != null && patient.on_vulnarable == 'Y' ?
                            <div className='mr-3'>
                                <Tooltip placement="topLeft" title={patient.on_vulnarable_comment !== null ? patient.on_vulnarable_comment : 'Vulnerable'}>
                                  <img src={vulnarableIcon} className="card_icons" />
                                </Tooltip>
                              </div>
                              : null
                          }
                        </div>
                      </div>
                      <div className='col'>
                        {
                          patient.service_name != null && patient.service_name != ''
                        ?
                          <span className='font-weight-bolds ml-1' style={{ color: '#5c5c5c', fontSize:'0.9rem' }}>{patient.service_name}</span>
                        :
                          null
                        }
                      </div>
                      {/* <div className="col-2 text-right">
                        <span style={{ color: "#d24350" }}>
                          {patient.booking_reason != null && patient.booking_reason != "null" ? patient.booking_reason : ""}
                        </span>
                      </div> */}
                      <div className="col-2 text-right">
                        {
                          patient.visit_status === 2 &&  patient.list != 'pending'
                            ?
                            <div className="patient_cta" style={{ position:"relative"} }>
                              {
                                patient.source == 'qms'
                                  ?
                                  patient.list == 'worklist' ? <div className="on-hold-btn mr-2 mb-3"><span className="on-hold">||</span>ON HOLD</div> : null
                                  :
                                  patient.list == 'worklist' ? <div className="on-hold-btn mr-2"><span className="on-hold">||</span>ON HOLD</div> : null
                              }
                            </div>
                            :
                            patient.list === 'completed'
                              ?
                                null
                              :
                              patient.list === 'worklist' ?
                                patient.wait_time != '0 Hrs 0 Mins'
                                  ?
                                  <div className="wait-time">
                                    <span className="font-weight-bold float-left"><span className="text-danger">LATE</span> | WAIT TIME: </span>
                                    {
                                      patient.wait_time != '0 Hrs 0 Mins'
                                        ?
                                        <span className="text-danger float-left">{patient.wait_time}</span>
                                        :
                                        null
                                    }
                                  </div>
                                  :
                                  null
                                :
                                  null
                        }
                      </div>
                      {/* && MHEA/Sukoon */}
                      <>
                     
                      </>
                      {
                        (patient.list === 'worklist' || patient.list === 'pending') && patient.visit_status != 1 && ((LS_SERVICE.get('user_type') == USERTYPES.doctor && practicing !== null && practicing?.status) || LS_SERVICE.get('user_type') == USERTYPES.nurse) && ((patient.source == 'default' && CHECKOUT_HOSPITALS_ENABLED.includes(hospital_id)) || (patient.source == 'qms'))
                      ?
                        <div className="col-2 mb-2 text-right">
                          {
                            (patient.list === 'worklist' || patient.list === 'pending') && patient.visit_status != 1 && LS_SERVICE.get('user_type') == USERTYPES.doctor && practicing !== null && practicing?.status && patient?.visit_id != null && ((patient.source == 'default' && CHECKOUT_HOSPITALS_ENABLED.includes(hospital_id)) || (patient.source == 'qms'))
                          ? 
                            <button onClick={(e) => this.checkOutAppointment(e, patient)} title={disabledCheckoutBookings.includes(patient.booking_id) ? 'Checkout in progress' : ''} disabled={disabledCheckoutBookings.includes(patient.booking_id)} className="checkout-token-card mr-0 mb-0">CHECK OUT</button>
                          :
                            null
                          }
                          {
                            patient.list === 'worklist' && patient.consult_type !== 1 && patient.source == 'qms'
                          ?
                            <label className="qms-token-card">
                              {patient.token}
                            </label>
                          :
                            null
                          }
                        </div>
                      :
                        <>
                          {
                            todaysDate == patient.token_date && patient.list === 'worklist' && patient.consult_type !== 1 && ((LS_SERVICE.get('user_type') == USERTYPES.doctor && practicing !== null && practicing?.status) || LS_SERVICE.get('user_type') == USERTYPES.nurse) && (patient.source == 'qms' || (patient.source == 'default' && CHECKOUT_HOSPITALS_ENABLED.includes(hospital_id)))
                          ?
                            <div className="col-2 mb-2 text-right">
                              {
                                todaysDate == patient.token_date && patient.list === 'worklist' && patient.consult_type !== 1 && patient?.visit_id != null && (LS_SERVICE.get('user_type') == USERTYPES.doctor && practicing !== null && practicing?.status) && (patient.source == 'qms' || (patient.source == 'default' && CHECKOUT_HOSPITALS_ENABLED.includes(hospital_id))) ? <button onClick={(e) => this.checkOutAppointment(e, patient)} title={disabledCheckoutBookings.includes(patient.booking_id) ? 'Checkout in progress' : ''} disabled={disabledCheckoutBookings.includes(patient.booking_id)} className="checkout-token-card mr-0">CHECK OUT</button>
                              :
                                null
                              }
                              {
                                patient.list === 'worklist' && patient.consult_type !== 1 && patient.source == 'qms' 
                              ? 
                                <label className="qms-token-card">{patient.token}</label> 
                              :
                                null
                              }
                            </div>
                          :
                            null
                          }
                        </>
                      }

                        
                      <div className={`col-12 ${patient.is_emergency && patient.is_corporate ? "mt-3" : ""}`}>
                        {(patient.is_emergency && patient.is_emergency == 0) ? <span className="text-danger mr-3">Emeregency</span> : ''}
                        {(patient.is_corporate && patient.is_corporate == 0) ? <span className="mr-3">Corporate</span> : ''}
                      </div>

                      {/* && patient.list === 'worklist' */}
                      {patient.consult_type == 1 ?
                        <>
                          <div className="col mt-2">
                          {/* <div className="col mt-2 mb-3"> */}
                            {/* <div className="time-line-container position-relative"> */}

                            <label className="virtual_consult_icon text-secondary mb-0">VIRTUAL CONSULT {patient.is_emergency == 1 ? <img style={{ width: '18px' }} src={EMERGENCY_ICO} alt="icon" className="img-responsive" /> : null} </label>  &nbsp;|
                            <span className="progress-appointment ml-2">Appointment <strong>{this.appointmentTime(patient)}</strong></span>
                            {
                              ((patient.list === 'completed' || patient.list === 'pending') && patient.booking_date != null)
                            ?
                              <>
                                <br />
                                <strong className="mb-0" style={{ textTransform: 'capitalize', fontSize: '0.9rem' }}>{moment(patient.booking_date).format('DD MMM, YYYY')}</strong>
                              </>
                            :
                              null
                            }
                            {
                                patient.virtual_call_time?
                                (<span className="progress-appointment ml-2">&nbsp;| Call start <strong>({patient.virtual_call_time?patient.virtual_call_time:null})</strong></span>)
                                :
                                null

                              }
                          </div>
                          <div className="col mt-2 mb-1 text-right" >
                            {patient.list === 'completed' ? this.completedVcAppointment(patient) : <>
                              <div className="patient_ctas">
                                {is_chat_enabled && (
                                  <img src={chat} alt="chat_ic" style={{ top: '0px', right: '10px', position: 'relative', height: '25px' }} className={patient.is_chat ? 'd-block mr-2' : 'd-none'} onClick={(e) => this.onPubNubChatClickStateLife(e, patient)} />
                                )}
                                {
                                  patient.source == 'default'
                                    ?
                                    (this.state.queue_date > moment().format('YYYY-MM-DD')) ? false : practicing !== null && practicing?.status ? <button onClick={(this.state.queue_date > moment().format('YYYY-MM-DD')) ? false : () => ISALLOWEDITSOAP(this.state.pres_modification, this.state.allow_hours, appointmentCompletedTime[patient.qms_token_id], 'patient-queue') ? this.callNext(patient) : null} className="btn btn-sm btn-primary text-uppercase px-2 py-1">{(this.state.is_qms_enabled == true) ? "CALL" : patient.list=='worklist'? "START CONSULT" :"COMPLETE"}</button> : null
                                    :
                                    (this.state.queue_date > moment().format('YYYY-MM-DD')) ? false : practicing !== null && practicing?.status ? <button onClick={(this.state.queue_date > moment().format('YYYY-MM-DD')) ? false : () => ISALLOWEDITSOAP(this.state.pres_modification, this.state.allow_hours, appointmentCompletedTime[patient.qms_token_id], 'patient-queue') ? this.callNextQMS(patient) : null} className="btn btn-sm btn-primary text-uppercase px-2 py-1">CALL</button> : null
                                }
                              </div>

                            </>}
                            {/* </div> */}
                          </div>
                        </> :
                        <>
                          <div className={"col-12 " + (patient.list === 'completed' && currentUserRole === USERTYPES.doctor ? 'mt-3' : 'mt-2')}>
                            <div className="time-line-container position-relative">
                              <label className="virtual_consult_icon" style={{ position: 'absolute', top: '20px' }}>OPD CONSULT {patient.is_emergency == 1 ? <img style={{ width: '18px' }} src={EMERGENCY_ICO} alt="icon" className="img-responsive" /> : null}</label>
                              {
                                ((patient.list === 'completed' || patient.list === 'pending') && patient.booking_date != null)
                              ?
                                <strong className="mb-0" style={{ position: 'absolute', top: '40px', textTransform: 'capitalize', fontSize: '0.9rem' }}>{moment(patient.booking_date).format('DD MMM, YYYY')}</strong>
                              :
                                null
                              }
                              <ul className="progress-indicator" >
                                <li className={this.progressBar(patient, 'appointment')}>
                                  <span className="bubble"></span>
                                  <span>{patient.is_walk_in == 'Walk In' ? 'Walk In Slot' : "Appointment"} <br/><b>{this.appointmentTime(patient)}</b></span>
                                </li>
                                <li className={this.progressBar(patient, 'arrived')}>
                                  <span className={(patient.is_emergency == 0 && patient.token_time && patient.token_time != null && moment(this.appointmentTime(patient), 'h:mm a').isBefore(moment(patient.token_time, 'h:mm a'))) ? "bubble late_red_bubble" : "bubble"}></span>
                                  <span style={(patient.is_emergency == 0 && patient.token_time && patient.token_time != null && moment(this.appointmentTime(patient), 'h:mm a').isBefore(moment(patient.token_time, 'h:mm a'))) ? { color: "#d24350" } : null}>Arrived {patient.token_time && patient.token_time != null ? <b><br/> {patient.token_time} </b> : null} </span>
                                  {(patient.is_emergency == 0 && patient.token_time && patient.token_time != null && moment(this.appointmentTime(patient), 'h:mm a').isBefore(moment(patient.token_time, 'h:mm a'))) ? <button className="btn btn-sm btn-primary" style={{ paddingTop: "0.15rem", paddingBottom: "0.15rem", cursor: "default" }}>Late</button> : null}
                                </li>
                                <li className={patient.billed_time && patient.billed_time != null ? 'completed' : ''}>
                                  <span className="bubble"></span>
                                  <span>Billed {patient.billed_time && patient.billed_time != null ? <b><br/>{patient.billed_time}</b> : null}</span>
                                </li>
                                <li className={this.progressBar(patient, 'vital')}>
                                  <span className="bubble"></span>
                                  {
                                    LS_SERVICE.get('user_type') == USERTYPES.doctor && patient.patient_forwarded_to == '2'
                                  ?
                                    <span className="red-bubble-outer"></span>
                                  :
                                    null
                                  }
                                  <span>Vitals Recorded{patient.vital_set && patient.vital_set_time ? <b><br/>{patient.vital_set_time}</b> : ''}</span>
                                </li>
                                <li className={patient.consult_start_time && patient.consult_start_time != null ? 'completed' : ''}>
                                  <span className="bubble"></span>
                                  <span className="consult">Consult Start {patient.consult_start_time ? <b><br/>{patient.consult_start_time}</b> : ''}</span>
                                </li>
                                
                                

                                <li className={this.progressBar(patient, 'visit')}>
                                  <span className="bubble"></span>
                                  <span className="consult">Consult End {patient.is_completed && patient.consult_end_time ? <b><br/>{patient.consult_end_time}</b> : ''}</span>
                                </li>

                                {
                                  patient.is_completed?
                                  <>
                                    <li className={patient.is_completed && patient.consult_updated_time != null ? 'completed' : ''}>
                                    <span className="bubble"></span>
                                    <span className="consult">Consult Update 
                                    
                                    {patient.consult_updated_time ? <b><br/>{patient.consult_updated_time}</b> : ''}
                                    
                                    </span>
                                  </li>
                                  </>
                                  :
                                  null

                                }
                                

                              </ul>
                              {(this.state.queue_date > moment().format('YYYY-MM-DD')) ? false : this.holdInfo(patient)}
                            </div>
                          </div>

                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            ))

            : null}

          {patients.length === 0 && currentUserRole === USERTYPES.doctor ? <div className="col-md-12">
            <div className="nostyle-link">
              <div className="card hospital-card shadow-box">
                <div className="card-body">
                  <div className="text-center">
                    <h4>No Appointment Available</h4>
                  </div>
                </div>
              </div>
            </div>
          </div> : null}

          {patients.length === 0 && this.state.nurseDoctorId.length !== 0 ? <div className="col-md-12">
            <div className="nostyle-link">
              <div className="card hospital-card shadow-box">
                <div className="card-body">
                  <div className="text-center">
                    <h4>No Appointment Available</h4>
                  </div>
                </div>
              </div>
            </div>
          </div> : null}

          {LS_SERVICE.get('user_type') == USERTYPES.nurse && this.state.nurseDoctorId.length == 0 && APPS_FEATURES_LIST.select_doctor_dropdown ? <div className="col-md-12">
            <div className="nostyle-link">
              <div className="card hospital-card shadow-box">
                <div className="card-body">
                  <div className="text-center">
                    <h4>Please select a doctor</h4>
                  </div>
                </div>
              </div>
            </div>
          </div> : null}
        </>
      )
    } else {
      const { homecare_patients, hospital_id } = this.state;
      return (
        <>
          {homecare_patients?.length > 0 && (
            homecare_patients.map((value, index) => {

              if (value.patient_vitals !== null && value.patient_vitals !== undefined) {
                value["patient_vitals_custom"] = this.makeVitalsData(Object.values(value.patient_vitals));
              }

              return (
                <div className="card mb-3" key={index} style={{ background: (value.booking_status_code !== 2 && value.test.days == value.test.duration) ? '#E5F7FE' : '' }}>
                  <div className="card-bodycustom card-body overflow-x p-3">
                    <div className="row">
                      <div className="col-12 mb-2">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="text-dark mb-2 card-title text-capitalize font-weight-bold">{value.patient.first_name
                            ? value.patient.first_name.toLowerCase()
                            : ""}{" "}
                            {value.patient.last_name
                              ? value.patient.last_name.toLowerCase()
                              : ""}{" "}
                            <span
                              className={
                                value.patient.dob === "0000-00-00" || value.patient.dob === null ? "hidden" : "text-age"
                              }
                            >
                              {moment().diff(value.patient.dob !== "0000-00-00"
                                ? value.patient.dob
                                : new Date(), 'years')} Years,{" "}
                              {GETGENDER(value.patient.gender)}
                              {UHID(value.patient.uhid) !== '' ? ' | ' + value.patient.uhid : ''}
                              {value.patient.relation !== null ? ` | ${value.patient.relation}` : ""}</span>
                          </div>
                          <div className="text-dark font-weight-bold mb-2 card-title d-flex flex-row align-items-center">
                            {
                              (LS_SERVICE.get('user_type') == USERTYPES.doctor)
                                ?
                                <>
                                  {(value.booking_status_code !== 2 && value.test.days == value.test.duration) && (
                                    <Link to={{
                                      pathname: `/patient/homecare/case-summary/${value.test.hospital_id}/${value.patient.id}/${HOMECARE_REFERENCE_TYPE}/${value.test.booking_id}`
                                    }} className="btn btn-sm btn-outline-secondary bg-white mr-3">CASE SUMMARY PENDING</Link>
                                  )}
                                </>
                                :
                                null
                            }
                            <div className="theme-text">{value.test.duration === 0 ? "New" : "Day " + value.test.days + '/' + value.test.duration}{" "}</div>
                            {/*<div className="text-age">&nbsp;| Package : {value.test.title} {value.test.duration} {value.test.duration == "1" ? "day" : "days"}</div>*/}
                            <div className="text-age">&nbsp;| Package : {value.test.title}</div>
                            {value.test.features.length > 0 ? (
                              <div className="ml-1 tooltip-img align-items-center justify-content-center">i</div>) : ""}
                            <div className="tooltip-text">
                              <span>
                                <p className="font-weight-bold mb-1">Package Inclusions :</p>
                                <ul className="pl-3 features-list">
                                  {value.test.features.length > 0 ? (
                                    value.test.features.map((e, i) => (
                                      <li key={i}>{e}</li>
                                    ))) : ""}
                                </ul>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {type !== 'homecare_completed' ? (
                        <>
                          <div className="col-4">
                            <div className={`latest-call ${value.remarks === null ? "visiblity_hidden" : ""}`}>
                              {value.patient_lastcall !== null ? (
                                <>
                                  <span>Last Call:</span>{' '}
                                  <span className="theme-text mb-0">
                                    {moment(value.patient_lastcall).format('D MMM Y | h:mm A')}
                                  </span>
                                </>
                              ) : ""}
                            </div>
                          </div>
                          <div className="col-4">
                            {this.showVitals(value)}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-4">
                            <div className={`latest-call ${value.completed_at === null ? "visiblity_hidden" : ""}`}>
                              {value.completed_at !== null ? (
                                <>
                                  <span>Completed on</span>{' '}
                                  <span className="textis-black font-weight-bold mb-0">
                                    {moment(value.completed_at).format('D MMM Y | h:mm A')}
                                  </span>
                                </>
                              ) : ""}
                            </div>
                          </div>
                          <div className="col-4">
                            <div className={`latest-call ${value.remarks === null ? "visiblity_hidden" : ""}`}>
                              {value.remarks !== null ? (
                                <>
                                  <span className="theme-text">Completed Early</span>{' '}
                                  <span className="mb-0">
                                    {value.remarks}
                                  </span>
                                </>
                              ) : ""}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="col-4">
                        <div className="d-flex align-items-center justify-content-end">
                          <div className="alert-box-1 mr-2">
                            {value.patient_alert.status ? (
                              <span className="btn btn-sm btn-warning" onClick={() => this.handleClickNav(value)}>PATIENT ALERT</span>
                            ) : ""}
                          </div>
                          <button
                            className={`btn btn-sm mt-auto bg-white btn-${value.is_call_patient.calling_day ? 'primary' : 'outline-secondary'}`}
                            onClick={e => this.handleHCPatientDetailsClick(e, value, value.is_call_patient.calling_day)}
                          >
                            {value.is_call_patient.calling_day ? 'CALL TODAY' : 'PATIENT DETAILS'}
                          </button>
                        </div>
                      </div>
                    </div>
                    {type !== 'homecare_completed' && (
                      <div className="row">
                        <div className={`col-12 mt-2`}>
                          <div className="readings-size overflow d-flex flex-row justify-content-between">
                            {
                            this.state.homecareVitals && this.state.homecareVitals.map((vd, index) => {                                                           
                               if (vd.id != 'V005' && vd.id != 'V006') {
                                return (
                                  <div className="d-flex flex-row readings-gap align-items-end" key={vd.id}>
                                    <div className="readings">
                                      <div className="border-text-readings small mb-0">{vd.display_name_short}
                                        <p className={`text-left font-weight-bold pl-0 reading-value mb-0`}>
                                          {this.patientVitalCustom(value, vd.id) ? (
                                            <span className={GET_VITALS_RANGE_CLASS(value.patient_vitals_custom[vd.id], this.state.constants.vitals_range[vd.id])}>
                                              {value.patient_vitals_custom[vd.id]}
                                            </span>
                                          ) : (
                                            <span>
                                              -
                                            </span>
                                          )}
                                          <span className="small-unit">{vd.display_value}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                               else if (vd.id == 'V005') {
                                return (
                                  <div className="d-flex flex-row readings-gap" key={vd.id}>
                                    <div className="readings">
                                      <div className="border-text-readings small mb-0">BP
                                        <p className={`text-left font-weight-bold pl-0 reading-value mb-0`}>
                                          {this.patientVitalCustom(value, 'V006') ? (
                                            <span className={GET_VITALS_RANGE_CLASS(value.patient_vitals_custom['V006'], this.state.constants.vitals_range['V006'])}>
                                              {value.patient_vitals_custom['V006']}
                                            </span>
                                          ) : (
                                            <span>
                                              -
                                            </span>
                                          )}/
                                          {this.patientVitalCustom(value, vd.id) ? (
                                            <span className={GET_VITALS_RANGE_CLASS(value.patient_vitals_custom[vd.id], this.state.constants.vitals_range[vd.id])}>
                                              {value.patient_vitals_custom[vd.id]}
                                            </span>
                                          ) : (
                                            <span>
                                              -
                                            </span>
                                          )}
                                          <span className="small-unit">{vd.display_value}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              } else {
                                return (
                                  <React.Fragment></React.Fragment>
                                )
                              }

                              
                            }
                            )
                            }
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )
            })
          )}

          {homecare_patients?.length === 0 && <div className="col-md-12">
            <div className="nostyle-link">
              <div className="card hospital-card shadow-box">
                <div className="card-body">
                  <div className="text-center">
                    <h4>No Appointment</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </>
      )
    }
  }

  worklist() {
    return this.patientCards('worklist', false);
  }

  completed() {
    return this.patientCards('completed', false);
  }

  review() {
    return this.patientCards('review');
  }

  pending() {
    return this.patientCards('pending', false);
  }

  homecare(type) {
    return this.patientCards(type, false);
  }

  // homecare_completed() {
  //   return this.patientCards('homecare_completed');
  // }

  callNextGlobal() {
    if (!APPS_FEATURES_LIST.call_next_btn) {
      return null;
    }

    return (
      <>
        {this.state.patients.length > 0 && this.state.incomplete_tokens > 0 && this.state.practicing.status ? <button type="button" onClick={() => this.callNext(this.state.patients[0])} className="btn btn-sm btn-primary">CALL NEXT</button> : null}
      </>
    )
  }

  callNextNew() {
    var { patients, practicing, is_qms_enabled, is_next_call_enabled } = this.state;
    var patient = null;
    let worklistPatients = [];
    for (let element of patients) {
      if (element.list === "worklist") {
        worklistPatients.push(element);
        if (element.visit_status != 2 && element.visit_status != 3) {
          patient = element;
          break;
        }
      }
    }

    if (patient == null) {
      patient = worklistPatients[0];
    }
    var qms_patients = [];
    if (patients?.length > 0) {
      qms_patients = patients.filter(x => (x.visit_status == 0 || x.visit_status == null) && x.source == 'qms');
    }
    var onhold_patients = [];
    if (qms_patients?.length <= 1) {
      onhold_patients = patients.filter(x => (x.visit_status == 2) && x.source == 'qms');
    }
    var next_in_queue_patient = [];
    next_in_queue_patient = qms_patients.concat(onhold_patients);
    if (practicing?.status && next_in_queue_patient?.length > 0 && this.state.activeTab == TAB_NAMES[0]) {
      return (
        <>
          <button type="button" onClick={() => this.callNextQMS(next_in_queue_patient[0])} className="btn btn-sm btn-primary  mr-2" style={{ height: "35px" }}>CALL NEXT</button>
        </>
      )
    }
    else if (is_qms_enabled && worklistPatients.length > 0 && practicing?.status && is_next_call_enabled && this.state.queue_date === moment().format('YYYY-MM-DD')) {
      return (
        <>
          <button type="button" onClick={() => this.callNext(patient)} className="btn btn-sm btn-primary  mr-2" style={{ height: "35px" }}>CALL NEXT</button>
        </>
      )
    }
  }

  // reCallAppointment(patient) {
  //   // callNext
  //   this.callNext(patient);

  //   let params = {status: 0, token_id: patient.qms_token_id }
  //   Axios.post(UPDATE_QMS_STATUS, qs.stringify(params)).then(response => {
  //     console.log(response)
  //   }).catch(error => console.log(error))
  // }

  // cancelAppointment(patient) {
  //   // callNext
  //   // this.callNext(patient);
  //   console.log('cancel appointment')
  //   let params = {status: 1, token_id: patient.qms_token_id }
  //   Axios.post(UPDATE_QMS_STATUS, qs.stringify(params)).then(response => {
  //     console.log(response)
  //   }).catch(error => console.log(error))
  // }

  // skipCallNextAppointment(currentPatient) {
  //   var { patients, practicing, is_qms_enabled, is_next_call_enabled } = this.state;

  //   var patient = null;
  //   let worklistPatients = [];
  //   for (let element of patients) {
  //     if (element.list === "worklist") {
  //       worklistPatients.push(element);
  //       if (currentPatient.booking_id != element.booking_id) {
  //         patient = element;
  //         break;
  //       }
  //     }
  //   }

  //   if (patient == null) {
  //     patient = worklistPatients[0];
  //   }

  //   if (is_qms_enabled && worklistPatients.length > 0 && practicing?.status && is_next_call_enabled && this.state.queue_date === moment().format('YYYY-MM-DD')) {
  //     // callNext
  //     this.callNext(patient);

  //     let params = {status: 2, token_id: currentPatient.qms_token_id }
  //     Axios.post(UPDATE_QMS_STATUS, qs.stringify(params)).then(response => {
  //       console.log(response)
  //     }).catch(error => console.log(error))
  //   }
  // }

  // skipCurrentPatient(patient, next_patient){
  //   if(patient.source == 'qms' && next_patient.source == 'qms'){
  //     let params = {status: 'skip', token_id: patient.token_id }
  //     Axios.post(UPDATE_QMS_STATUS, qs.stringify(params)).then(response => {
  //       console.log(response)
  //     })
  //   }
  // }

  async callNext(patient) {

    const currentUserRole = LS_SERVICE.get('user_type');
    LS_SERVICE.set('call_patient_detail', patient);
    LS_SERVICE.set('call_patient_id', patient.patient_id);
    LS_SERVICE.set('call_patient_qms_token_id', patient.qms_token_id);
    await LS_SERVICE.set('reference_type',0);
    await LS_SERVICE.set('reference_id',patient.qms_token_id);
    if (patient?.is_enable_for_multiple_calling) {
      LS_SERVICE.set('is_mpc_enabled_' + patient.qms_token_id, patient.is_enable_for_multiple_calling);
    }
    // LS_SERVICE.set('is_mpc_enabled_' + patient.qms_token_id, true);
    const hospital_id = this.state.hospital_id;

    if (this.state.queue_date > moment().format('YYYY-MM-DD')) {
      await this.setState({
        goToPatient: patient
      });
      this.dontStartPractice('emr')
      return
    }
    if (!this.state.practicing?.status && currentUserRole == USERTYPES.doctor) {
      this.setState({
        practiceNotStarted: true,
        goToPatient: patient
      });
      return;
    }
    if (currentUserRole == USERTYPES.doctor) {
      if (this.state.practicing?.status && (patient.list === 'completed') && currentUserRole == USERTYPES.doctor) {
        patient["isEditRxTrue"] = true;
        return this.completePendingVisit(patient);
      } /* else if (patient.list !== 'worklist' && currentUserRole == USERTYPES.nurse) {

      } */ else if (patient.list == 'pending' || patient.list == 'completed' ||  patient.list == 'worklist') {
        return this.callPatient(patient);
      } else {
        return;
      }
    } else {
      if (patient.list != 'completed') {
        LS_SERVICE.set('selectedDoctorIdAppoinement', patient.doctor_id);
        //this.props.history.push('/patient/todays-visit/objective/' + patient.patient_id + '/0/' + patient.qms_token_id);
        this.props.history.push({ pathname: '/patient/todays-visit/objective/' + hospital_id + '/' + patient.patient_id + '/0/' + patient.qms_token_id, state: { patient } });
      }
      return;
    }
  }

  async nursePatientRevisit(e, patient) {
    const hospital_id = this.state.hospital_id;
    await LS_SERVICE.set('reference_type',0);
    await LS_SERVICE.set('reference_id',patient.qms_token_id);
    this.props.history.push({ pathname: '/patient/todays-visit/objective/' + hospital_id + '/' + patient.patient_id + '/0/' + patient.qms_token_id, state: { patient } });
  }

  async callNextQMS(patient) {

    const currentUserRole = LS_SERVICE.get('user_type');
    LS_SERVICE.set('call_patient_detail', patient);
    LS_SERVICE.set('call_patient_id', patient.patient_id);
    LS_SERVICE.set('call_patient_qms_token_id', patient.qms_token_id);
    const hospital_id = this.state.hospital_id;
    await LS_SERVICE.set('reference_type',0);
    await LS_SERVICE.set('reference_id',patient.qms_token_id);
    if (!this.state.practicing?.status && currentUserRole == USERTYPES.doctor) {
      this.setState({
        practiceNotStarted: true,
        goToPatient: patient
      });
      return;
    }
    if (currentUserRole == USERTYPES.doctor) {
      let params = { status: 'call', token_id: patient.token_id, hospital_id: hospital_id }
      if (this.state.current_patient != null && (this.state.current_patient?.source == 'qms' && patient.source == 'qms')) {
        let skipparams = { status: 'skip', token_id: this.state.current_patient.token_id, hospital_id: hospital_id }
        Axios.post(UPDATE_QMS_STATUS, qs.stringify(skipparams)).then(response => {
          Axios.post(UPDATE_QMS_STATUS, qs.stringify(params)).then(responseb => {
            // console.log(responseb)
          }).catch(error => console.log(error))
        })
      }
      else {
        Axios.post(UPDATE_QMS_STATUS, qs.stringify(params)).then(response => {
          console.log(response)
        }).catch(error => console.log(error))
      }
      // || patient.list === 'review'
      if (this.state.practicing?.status && ( patient.list === 'completed') && currentUserRole == USERTYPES.doctor) {
        return this.completePendingVisit(patient);
      } /* else if (patient.list !== 'worklist' && currentUserRole == USERTYPES.nurse) {

      } */ else if (patient.list == 'pending' ||  patient.list == 'worklist') {
        return this.callPatient(patient);
      } else {
        return;
      }
    } else {
      if (patient.list != 'completed') {
        LS_SERVICE.set('selectedDoctorIdAppoinement', patient.doctor_id);
        //this.props.history.push('/patient/todays-visit/objective/' + patient.patient_id + '/0/' + patient.qms_token_id);
        this.props.history.push({ pathname: '/patient/todays-visit/objective/' + hospital_id + '/' + patient.patient_id + '/0/' + patient.qms_token_id, state: { patient } });
      }
      return;
    }
  }

  async callNextQMSNurse(patient, action) {
    const hospital_id = this.state.hospital_id;
    if (action === 'forward') {
      let { forworedTokenIds } = this.state;
      forworedTokenIds.push(patient.token_id);
      this.setState({
        forworedTokenIds: forworedTokenIds,
        skipTokenIds: forworedTokenIds
      })
    }
    if (action == 'call' && this.state.nurse_current_patient && this.state.nurse_current_patient?.length > 0) {
      for (var thispatient of this.state.nurse_current_patient) {
        let skipparams = { status: 'skip', token_id: thispatient.token_id, qms_token_id: thispatient.qms_token_id, hospital_id: hospital_id }
        await Axios.post(UPDATE_QMS_STATUS_NURSE, qs.stringify(skipparams));
      }
    }
    if (action == 'skip') {
      let { skipTokenIds } = this.state;
      skipTokenIds.push(patient.token_id);
      this.setState({
        skipTokenIds: skipTokenIds
      })

      let skipparams = { status: 'skip', token_id: patient.token_id, qms_token_id: patient.qms_token_id, hospital_id: hospital_id }
      await Axios.post(UPDATE_QMS_STATUS_NURSE, qs.stringify(skipparams));
    }
    let params = { status: action, token_id: patient.token_id, qms_token_id: patient.qms_token_id, hospital_id: hospital_id }
    Axios.post(UPDATE_QMS_STATUS_NURSE, qs.stringify(params)).then(response => { }).catch(error => console.log(error))
    if (action == 'call') {
      this.callNextQMS(patient);
    }
    else {
      this.initQueue();
    }
  }

  // handleSoapBasicConfig = (soapConfig, patient) => {
  //   let soapConfigData = [];
  //   if (LS_SERVICE.has('soapConfigData')) {
  //     soapConfigData = LS_SERVICE.get('soapConfigData');
  //     const index = soapConfigData.find(scd => scd.reference_id === patient.qms_token_id);
  //     if (index === undefined) {
  //       soapConfigData = [...soapConfigData, {
  //         reference_id: patient.qms_token_id,
  //         patient_id: patient.patient_id,
  //         hospital_id: patient.hospital_id,
  //         soapConfig,
  //       }]
  //     }
  //   } else {
  //     soapConfigData = [{
  //       reference_id: patient.qms_token_id,
  //       patient_id: patient.patient_id,
  //       hospital_id: patient.hospital_id,
  //       soapConfig
  //     }]
  //   }
  //   LS_SERVICE.set('soapConfigData', soapConfigData);
  //   LS_SERVICE.set('soapConfig', soapConfig);
  // }

  async callPatient(patient) {
    const { soapConfig, hospital_id } = this.state;
    this.setState({ showSpinner: true });
    await LS_SERVICE.set('reference_type',0)
    const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
    // console.log('>> patientinfo', patient);
    // const soapConfig = LS_SERVICE.get('soapConfig');
    const currentUserRole = LS_SERVICE.get('user_type');
    let is_junior_doctor = LS_SERVICE.get('is_junior_doctor');
    let doctor_id = null;
    if (is_junior_doctor != undefined && is_junior_doctor != null) {
      if (is_junior_doctor == true) {
        doctor_id = patient.doctor_id;
      }
    }
    await LS_SERVICE.set('reference_id',patient.qms_token_id);
    
    const visit = await API_SERVICE.post(TODAYS_VISIT, {
      reference_id: patient.qms_token_id,
      reference_type: 0,
      patient_id: patient.patient_id,
      hospital_id: patient.hospital_id,
      doctor_id: doctor_id
    });
    var todaysVisitUrl = [
      '/patient', 'todays-visit',
      patient.hospital_id,
      patient.patient_id,
      0,
      patient.qms_token_id
    ].join('/');


    this.setState({ showSpinner: false });
    if (visit.status) {
      // this.handleSoapBasicConfig(soapConfig, patient);
      if (soapConfig === BASIC) {
        this.props.history.push({
          // pathname: '/patient/todays-visit/0/' + patient.qms_token_id,
          pathname: todaysVisitUrl,
          state: { patient }
        });
        return;
      }
      else if (LS_SERVICE.get('soap_type') == 'single') {
        let soapurl = '/patient/soapss/HOSPITALID/PATID/REFTYPE/REFID';
        soapurl = soapurl.replace("HOSPITALID", patient.hospital_id);
        soapurl = soapurl.replace("REFID", patient.qms_token_id);
        soapurl = soapurl.replace("REFTYPE", 0);
        soapurl = soapurl.replace("PATID", patient.patient_id);
        todaysVisitUrl = soapurl
      }

      if (isTodayVisitArr.includes(JSON.stringify(patient.qms_token_id))) {
        LS_SERVICE.set('isTodayVisit', isTodayVisitArr.filter(e => e != patient.qms_token_id))
      }
      // if (currentUserRole == USERTYPES.doctor && isTodayVisitArr.includes(JSON.stringify(patient.qms_token_id)) !== true) {
      if (currentUserRole == USERTYPES.doctor) {
        if (this.state.goToSoapPatientUrl != null) {
          if (LS_SERVICE.get('soap_type') == 'soap') {
            let soapurl = this.state.goToSoapPatientUrl;
            soapurl = soapurl.replace("HOSPITALID", patient.hospital_id);
            soapurl = soapurl.replace("REFID", patient.qms_token_id);
            soapurl = soapurl.replace("REFTYPE", 0);
            soapurl = soapurl.replace("PATID", patient.patient_id);
            this.props.history.push({
              pathname: soapurl,
              state: { patient }
            })
          } else if (LS_SERVICE.get('soap_type') == 'single') {
            let soapurl = '/patient/soapss/HOSPITALID/PATID/REFTYPE/REFID';
            soapurl = soapurl.replace("HOSPITALID", patient.hospital_id);
            soapurl = soapurl.replace("REFID", patient.qms_token_id);
            soapurl = soapurl.replace("REFTYPE", 0);
            soapurl = soapurl.replace("PATID", patient.patient_id);
            this.props.history.push({
              pathname: soapurl,
              state: { patient }
            })
          }
        } else {
          if (LS_SERVICE.get('soap_type') == 'single') {
            let soapurl = '/patient/soapss/HOSPITALID/PATID/REFTYPE/REFID';
            soapurl = soapurl.replace("HOSPITALID", patient.hospital_id);
            soapurl = soapurl.replace("REFID", patient.qms_token_id);
            soapurl = soapurl.replace("REFTYPE", 0);
            soapurl = soapurl.replace("PATID", patient.patient_id);
            this.props.history.push({
              pathname: soapurl,
              state: { patient }
            })
          } else {
            this.props.history.push({
              // pathname: '/patient/todays-visit/0/' + patient.qms_token_id,
              pathname: todaysVisitUrl,
              state: { patient }
            });
          }
        }
      } else {
        this.props.history.push({
          // pathname: '/patient/todays-visit/0/' + patient.qms_token_id,
          pathname: todaysVisitUrl,
          state: { patient }
        });
      }
    }
    return;
  }

  async completePendingVisit(patient) {
    await LS_SERVICE.set('reference_type',0)
    await LS_SERVICE.set('reference_id',patient.qms_token_id);
    const todaysVisitUrl = [
      '/patient', 'todays-visit',
      patient.hospital_id,
      patient.patient_id,
      0,
      patient.qms_token_id
    ].join('/');
        
    if (LS_SERVICE.get('soap_type') == 'soap' || this.state.soapConfig === BASIC) {
      this.props.history.push({
        // pathname: '/patient/todays-visit/0/' + patient.qms_token_id,
        pathname: todaysVisitUrl,
        state: { patient }
      });
    }
    else {
      let soapurl = '/patient/soapss/HOSPITALID/PATID/REFTYPE/REFID';
      soapurl = soapurl.replace("HOSPITALID", patient.hospital_id);
      soapurl = soapurl.replace("REFID", patient.qms_token_id);
      soapurl = soapurl.replace("REFTYPE", 0);
      soapurl = soapurl.replace("PATID", patient.patient_id);
      this.props.history.push({
        pathname: soapurl,
        state: { patient }
      });
    }
  }

  async dontStartPractice(landing_page) {
    await LS_SERVICE.set('reference_type',0)
    const patient = this.state.goToPatient;
    await LS_SERVICE.set('reference_id',patient.qms_token_id);
    const { soapConfig, hospital_id } = this.state;
    this.setState({
      practiceNotStarted: false,
      goToPatient: {}
    }, () => {
      const { hospital_id } = this.state;
      // this.handleSoapBasicConfig(soapConfig, patient);
      if (landing_page == 'emr') {
        var todaysVisitUrl = [
          '/patient', 'todays-visit',
          patient.hospital_id,
          patient.patient_id,
          0,
          patient.qms_token_id
        ].join('/');
        if (LS_SERVICE.get('soap_type') != 'soap') {

        }
        const currentUserRole = LS_SERVICE.get('user_type');
        const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
        // this.handleSoapBasicConfig(soapConfig, patient);
        if (soapConfig === BASIC) {
          this.props.history.push({
            // pathname: '/patient/todays-visit/0/' + patient.qms_token_id,
            pathname: todaysVisitUrl,
            state: { patient }
          });
          return;
        }
        else if (LS_SERVICE.get('soap_type') == 'single') {
          let soapurl = '/patient/soapss/HOSPITALID/PATID/REFTYPE/REFID';
          soapurl = soapurl.replace("HOSPITALID", patient.hospital_id);
          soapurl = soapurl.replace("REFID", patient.qms_token_id);
          soapurl = soapurl.replace("REFTYPE", 0);
          soapurl = soapurl.replace("PATID", patient.patient_id);
          todaysVisitUrl = soapurl
        }

        if (isTodayVisitArr.includes(JSON.stringify(patient.qms_token_id))) {
          LS_SERVICE.set('isTodayVisit', isTodayVisitArr.filter(e => e != patient.qms_token_id))
        }
        // if (currentUserRole == USERTYPES.doctor && isTodayVisitArr.includes(JSON.stringify(patient.qms_token_id)) !== true) {
        if (currentUserRole == USERTYPES.doctor) {
          if (this.state.goToSoapPatientUrl != null) {
            if (LS_SERVICE.get('soap_type') == 'soap') {
              let soapurl = this.state.goToSoapPatientUrl;
              soapurl = soapurl.replace("HOSPITALID", patient.hospital_id);
              soapurl = soapurl.replace("REFID", patient.qms_token_id);
              soapurl = soapurl.replace("REFTYPE", 0);
              soapurl = soapurl.replace("PATID", patient.patient_id);
              this.props.history.push({
                pathname: soapurl,
                state: { patient }
              })
            } else {
              let soapurl = '/patient/soapss/HOSPITALID/PATID/REFTYPE/REFID';
              soapurl = soapurl.replace("HOSPITALID", patient.hospital_id);
              soapurl = soapurl.replace("REFID", patient.qms_token_id);
              soapurl = soapurl.replace("REFTYPE", 0);
              soapurl = soapurl.replace("PATID", patient.patient_id);
              this.props.history.push({
                pathname: soapurl,
                state: { patient }
              })
            }
          } else {
            this.props.history.push({
              // pathname: '/patient/todays-visit/0/' + patient.qms_token_id,
              pathname: todaysVisitUrl,
              state: { patient }
            });
          }
        } else {
          this.props.history.push({
            // pathname: '/patient/todays-visit/0/' + patient.qms_token_id,
            pathname: todaysVisitUrl,
            state: { patient }
          });
        }
      }
      else {
        this.props.history.push(`/patient/past-visit/${hospital_id}/${patient.patient_id}/0/${patient.qms_token_id}`);
      }
    });
  }

  /*yesStartPractice() {
    practiceService.init({ initiater: 'Patient Queue', status: 'start' });
  
    this.setState({
      practiceNotStarted: false,
    });
  }*/

  async yesStartPractice() {
    this.setState({ practiceLoader: true });

    const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
      hospital_id: this.state.hospital_id,
      practice_session: 'start'
    });

    let is_practicing;
    if (practice.data && practice.data.is_practicing === 'Y') {
      is_practicing = true;
    } else {
      is_practicing = false;
    }

    this.setState({
      practiceLoader: false,
      practicing: {
        status: is_practicing,
        hospital_id: this.state.hospital_id
      }
    });

    if (is_practicing) {
      LS_SERVICE.set('practicing', {
        status: true,
        hospital_id: this.state.hospital_id
      });
      queuePracticeService.init({ headerStart: true });
    } else {
      LS_SERVICE.delete('practicing');
      queuePracticeService.init({ headerStart: false });
    }
    practiceService.init({ initiater: 'Patient Queue', status: 'start' });

    this.setState({
      practiceNotStarted: false,
    });
  }

  checkDrForMarkArrival(doctor_id) {
    const doctorsList = LS_SERVICE.get('nurse_doctors');
    let flag = false;
    if (doctorsList?.length > 0) {
      for (const doctor of doctorsList) {
        //console.log("doctorsList:", doctor.is_mark_arrival, doctor_id, doctor.id, doctor.name);
        if (doctor.id == doctor_id && doctor.is_mark_arrival == 1) {
          //console.log("is_mark_arrival111:", doctor.is_mark_arrival);
          flag = true;
          break;
        }
      }
    }
    return flag;
  }

  handleHCPatientDetailsClick = async (e, patientData, isCallToday = 0) => {
    e.preventDefault();

    const { hospital_id } = this.state;
    const { patient, id } = patientData;

    var PARAMS = {
      patient_id: patient.id,
      doctor_id: patientData?.doctor?.id,
      hospital_id: hospital_id,
      reference_id: id,
      reference_type: 2,
      patient: patient
    }
    var call_patient_detail = {
      address: patientData?.patient?.address_1,
      booking_code: patientData?.booking_code,
      booking_date: patientData?.booking_date,
      booking_id: patientData?.id,
      booking_status: patientData?.booking_status,
      dob: patientData?.patient?.dob,
      doctor_id: patientData?.doctor?.id,
      doctor_name: patientData?.doctor?.name,
      fullname: patientData?.patient?.full_name,
      gender: patientData?.patient?.genderyy,
      hospital_id: patientData?.test?.hospital_id,
      list: "Homecare",
      mobile_no: patientData?.patient?.mobile_no,
      patient_first_name: patientData?.patient?.first_name,
      patient_id: patientData?.patient?.id,
      patient_last_name: patientData?.patient?.last_name,
      patient_middle_name: patientData?.patient?.middle_name,
      patient_type: patientData?.patient?.patient_type,
      qms_token_id: patientData?.id,
      uhid: patientData?.patient?.uhid,
    }
    LS_SERVICE.set('call_patient_detail', call_patient_detail);
    LS_SERVICE.set('call_patient_id', patientData?.patient?.id);
    LS_SERVICE.set('call_patient_qms_token_id', patientData?.id);
    LS_SERVICE.set('reference_type',2);
    LS_SERVICE.set('reference_id',id);
    Axios.post(CALL_HOMECARE_PATIENT, PARAMS).then(response => {
      LS_SERVICE.set('last_called_homecare',patientData);
    }).catch(error => {
      console.log(error)
    });


    let hideTab ={name:"homecare_tabs"};
    hideExtraTab.init({hideTab});
    this.props.history.push({
      pathname: `/patient/homecare/details/${hospital_id}/${patient.id}/${HOMECARE_REFERENCE_TYPE}/${id}`,
      state: { isCallToday },
    });
  }

  /* getPatientAge(dob) {
    var ageM = moment().diff(moment(dob, 'YYYY-MM-DD'), 'M');
    var ageY = ageM / 12;
    var remainM = ageM % 12;
    ageY = Math.floor(ageY);
    var ageText = '';
    if (ageM == 0)
        ageText = ageD + ' Day' + (ageD == 1 ? '' : 's');
    else if (ageY < 1)
        ageText = ageM + ' Month' + (ageM == 1 ? '' : 's');
    else if (ageY <= 5){
        if(remainM != 0){
            ageText = ageY + ' Year' + (ageY == 1 ? '' : 's ') + remainM + ' Month' + (remainM == 1 ? '' : 's');
        }else{
            ageText = ageY + ' Year' + (ageY == 1 ? '' : 's');
        }
    }
    else
        ageText = ageY + ' Years';
    return ageText;
  } */

  getPatientAge(dob) {
    var ageD = Math.floor(moment().diff(moment(dob, 'YYYY-MM-DD'), 'D') / (1000 * 3600 * 24));
    var ageM = moment().diff(moment(dob, 'YYYY-MM-DD'), 'M');
    var ageY = ageM / 12;
    var remainM = ageM % 12;
    ageY = Math.floor(ageY);
    var ageText = '';
    if (ageM == 0)
      ageText = ageD + ' Day' + (ageD == 1 ? '' : 's');
    else if (ageY < 1)
      ageText = ageM + ' Month' + (ageM == 1 ? '' : 's');
    else if (ageY <= 5) {
      if (remainM != 0) {
        ageText = ageY + ' Year' + (ageY == 1 ? '' : 's ') + remainM + ' Month' + (remainM == 1 ? '' : 's');
      } else {
        ageText = ageY + ' Year' + (ageY == 1 ? '' : 's');
      }
    }
    else
      ageText = ageY + ' Years';
    return ageText;
  }


  handleClickNav = (data) => {
    const { patient: { id }, patient_alert: { boooking_id } } = data;
    const hospital_id = LS_SERVICE.get('slot_hospital_id');

    let audio = document.getElementById('patient_alarm');
    audio.pause();


    var patientData = data;

    const { patient, test_booking_id } = patientData;

    var PARAMS = {
      patient_id: patient.id,
      doctor_id: patientData?.doctor?.id,
      hospital_id: hospital_id,
      reference_id: test_booking_id,
      reference_type: 2,
      patient: patient
    }
    var call_patient_detail = {
      address: patientData?.patient?.address_1,
      booking_code: patientData?.booking_code,
      booking_date: patientData?.booking_date,
      booking_id: patientData?.id,
      booking_status: patientData?.booking_status,
      dob: patientData?.patient?.dob,
      doctor_id: patientData?.doctor?.id,
      doctor_name: patientData?.doctor?.name,
      fullname: patientData?.patient?.full_name,
      gender: patientData?.patient?.genderyy,
      hospital_id: patientData?.test?.hospital_id,
      list: "Homecare",
      mobile_no: patientData?.patient?.mobile_no,
      patient_first_name: patientData?.patient?.first_name,
      patient_id: patientData?.patient?.id,
      patient_last_name: patientData?.patient?.last_name,
      patient_middle_name: patientData?.patient?.middle_name,
      patient_type: patientData?.patient?.patient_type,
      qms_token_id: patientData?.id,
      uhid: patientData?.patient?.uhid,
    }
    LS_SERVICE.set('call_patient_detail', call_patient_detail);
    LS_SERVICE.set('call_patient_id', patientData?.patient?.id);
    LS_SERVICE.set('call_patient_qms_token_id', patientData?.id);
    LS_SERVICE.set('reference_type',2);
    LS_SERVICE.set('reference_id',test_booking_id);
    Axios.post(CALL_HOMECARE_PATIENT, PARAMS).then(response => {
      LS_SERVICE.set('last_called_homecare',patientData);
    }).catch(error => {
      console.log(error)
    });


    this.props.history.push({
      pathname: `/patient/homecare/details/${hospital_id}/${id}/${HOMECARE_REFERENCE_TYPE}/${boooking_id}`,
      state: { isCallToday: 0 }
    });
  }

  /* Pagination start*/
  homecare_paginate = (page, pageSize) => {
    this.setState({
      homecare_page: page,
    }, () => {
      this.getHomecareCards(page, pageSize, this.state.activeTab);
    })
  }
  /* Pagination end*/

  handleTabChange = (e, tab) => {
    e !== null && e.preventDefault();

    this.setState({
      is_next_call_enabled: (tab === TAB_NAMES[0]) ? true : false,
      isCalendarEnabeld: (tab === TAB_NAMES[0] || tab === TAB_NAMES[1]) ? false : true,
      search_keyword: ''
    });

    if (tab === TAB_NAMES[3] || tab === TAB_NAMES[4]) {
      this.setState({
        hcCompSearch: '',
        homecare_page: 1
      }, () => {
        this.getHomecareCards(this.state.homecare_page, this.state.per_page, tab)
      }
      )
    }

    if ((this.state.queue_date > moment().format('YYYY-MM-DD') && tab === TAB_NAMES[1]) || (this.state.queue_date < moment().format('YYYY-MM-DD') && tab === TAB_NAMES[0])) {
      this.handleAppointmentDate(moment().format('YYYY-MM-DD'));
    }

    this.setState({
      activeTab: tab,
      homecare_page: 1
    }, this.tabContentShow)

    LS_SERVICE.set('activeTab', tab);
  }

  tabContentShow = _ => {
    if (this.tabContentShowTimer !== '') return;

    this.tabContentShowTimer = setTimeout(() => {
      let elem = document.querySelectorAll('.tab-pane.fade');
      elem.forEach(e => e.classList.remove('show'));
      let activeElem = document.querySelector('.tab-pane.fade.active');
      if (activeElem !== null && activeElem !== undefined) {
        activeElem.classList.add('show');
        clearTimeout(this.tabContentShowTimer);
        this.tabContentShowTimer = '';
      }
    }, 0)
  }

  hospitalChange = (id, optionInfo) => {
    let assistance_detail = {};
    assistance_detail.hospital_name = optionInfo.children;
    assistance_detail.assistance_number = optionInfo.assistant_number;
    LS_SERVICE.set('assistance_detail', assistance_detail);
    assistanceNumberService.init({ assistance_detail })
    LS_SERVICE.delete('activeTab');
    this.props.history.push(`/patient-queue/${id}`);
    this.setState({
      hospital_id: +id,
      loading: true,
      search_keyword: ''
    }, () => this.componentDidMountFn())
  }

  handleHCSearch = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    }, () => {
      this.onSearch$.next();
    })
  }

  onSearch = e => {
    const { value } = e.target;
    this.setState({
      search_keyword: value,
    })
  }

  handleAppointmentDate = async (val) => {

    await this.setState({
      queue_date: moment(val).format('YYYY-MM-DD'),
      showSpinner: true
    });

    if (moment(val).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
      this.handleTabChange(null, 'completed');
    }

    if (moment(val).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
      this.handleTabChange(null, 'worklist');
    }

    // set appointment date 
    LS_SERVICE.set('appointment_date', moment(val).format('YYYY-MM-DD'));
    if( LS_SERVICE.get('senior_doctor_id')!='' && this.state.seniorDoctorId.length>0){
      this.loadSeniorDoctorAppointment()
    }else{
    this.initQueue();
    }
  }

  loadSeniorDoctorAppointment(){
    this.loadPatientQueue('is_junior_doctor');
  }

  handleCancel = () => {
    this.setState({ doctorSelectionModal: false });
  }

  nurseSelectedDoctor = async (e, props) => {
    const { nurseDoctorId } = this.state;
    if (e.target.checked) {
      nurseDoctorId.push(e.target.value);
    } else {
      var index = nurseDoctorId.indexOf(e.target.value)
      if (index !== -1) {
        nurseDoctorId.splice(index, 1);
      }
    }

    if (!nurseDoctorId || nurseDoctorId == NaN || nurseDoctorId.length == 0) {
      notification.error({
        message: 'Please select doctor',
        placement: 'topRight'
      });

      this.setState({
        worklist_count: 0,
        completed_count: 0
      });

      this.setState({ patients: [] });
      this.setState({ nurseDoctorId: [], patientQueueHitAfter10secs: false, patients: [] });
      await LS_SERVICE.set('nurse_doctor_id', '');

      this.loadPatientQueue();

      return;
    }

    if (nurseDoctorId && nurseDoctorId.length > 0) {
      {/* this.setState({ [props[0].value]: props[0].arrival || 0 }); */ }
      this.setState({ nurseDoctorId: nurseDoctorId, loading: true }, async () => {
        await LS_SERVICE.set('nurse_doctor_id', nurseDoctorId);

        this.loadPatientQueue();
      });
    }
  }

  seniorSelectedDoctor = async (e, props) => {
    const { seniorDoctorId } = this.state;

    if (e.target.checked) {
      seniorDoctorId.push(e.target.value);
    } else {
      var index = seniorDoctorId.indexOf(e.target.value)
      if (index !== -1) {
        seniorDoctorId.splice(index, 1);
      }
    }

    if (!seniorDoctorId || seniorDoctorId == NaN || seniorDoctorId.length == 0) {
      notification.error({
        message: 'Please select doctor',
        placement: 'topRight'
      });

      this.setState({
        worklist_count: 0,
        completed_count: 0,
        pending_count: 0
      });

      this.setState({ patients: [] });
      this.setState({ seniorDoctorId: [], patientQueueHitAfter10secs: false, patients: [] });
      await LS_SERVICE.set('senior_doctor_id', '');

      this.loadPatientQueue();

      return;
    }

    if (seniorDoctorId && seniorDoctorId.length > 0) {
      {/* this.setState({ [props[0].value]: props[0].arrival || 0 }); */ }
      this.setState({ seniorDoctorId: seniorDoctorId, loading: true }, async () => {
        await LS_SERVICE.set('senior_doctor_id', seniorDoctorId);
        this.loadPatientQueue('is_junior_doctor');
      });
    }
  }
  async clearSeniorDoctorList() {
    LS_SERVICE.set('senior_doctor_id', '');
    this.setState({
      worklist_count: 0,
      completed_count: 0,
      pending_count: 0,
      seniorDoctorId: []
    });
   await this.initQueue()
    this.loadPatientQueue();

  }

  handlePreviousNextDate = async (type) => {
    const { previous_date, next_date } = this.state
    let appointment_date;
    if (type === "previous") {
      appointment_date = moment(this.state.queue_date).subtract(1, 'days').format('YYYY-MM-DD');
    } else if (type === "next") {
      appointment_date = moment(this.state.queue_date).add(1, 'days').format('YYYY-MM-DD');
    }

    this.handleAppointmentDate(appointment_date);
  }

  // enterCard(patient,index){
    //     var appointmentCompletedTime = LS_SERVICE.get('appointmentCompletedTime') ?? {};
    //     if(this.state.queue_date > moment().format('YYYY-MM-DD') && patient.consult_type == 2){
    //       return false
    //     }else{
    //       if(patient.list=='pending' &&  ISALLOWEDITSOAP(this.state.pres_modification, this.state.allow_hours, appointmentCompletedTime[patient.qms_token_id], 'patient-queue') && (patient.list === 'worklist' && patient.source == 'default') || (patient.list != 'worklist' && patient.list != 'review')){
    //         this.callNext(patient)
    //       }else{
    //         if((patient.list === 'worklist' && patient.source == 'default') || (patient.list != 'worklist' && patient.list != 'review')){
    //           this.callNext(patient)
    //         }else{
    //           return false;
    //         }
    //       }
    //     }
    // }


  render() {
    const { activeTab, hospital_id, queue_date, previous_date, next_date, isCalendarEnabeld, is_junior_doctor, senior_doctor, seniorDoctorId, } = this.state;
    const SearchCondition = [TAB_NAMES[0], TAB_NAMES[1], TAB_NAMES[2], TAB_NAMES[5]];
    let deaultHosiptal = this.state.slots?.find((slot, index) => {
      return slot.id == LS_SERVICE.get('slot_hospital_id')
    })
    return (
      <>
        <Spin key={hospital_id} spinning={(!this.state.loading && this.state.showSpinner) ? this.state.showSpinner : null} indicator={antIcon}>
          <div className="container-fluid pr-3" id="patient-queue">
            <div className="emrfrm" id="patient-queue-form" autoComplete="off">

              {/* {(LS_SERVICE.get('user_type') == USERTYPES.doctor) ?
                <QueueCalender queue_date={queue_date} previous_date={previous_date} next_date={next_date} isCalendarEnabeld={isCalendarEnabeld} handleAppointmentDate={this.handleAppointmentDate} />
                : null} */}

              <div className="container-fluid patient-queue-calendar mt-2 mb-2" style={{ position: "relative" }}>
                <div className={(isCalendarEnabeld) ? "show" : "hide"} style={{ position: "absolute", backgroundColor: "#eff0f0", opacity: "0.7", top: 0, right: 0, bottom: 0, left: 0, zIndex: 1, width: "18%",borderRadius:'5px' }}></div>

                {LS_SERVICE.get('user_type') == USERTYPES.doctor ? <div className="row mt-3" >
                  <div className="col-xl-3 col-4 col-lg-4 d-flex align-self-top">
                    <span className="">
                      <a disabled={(previous_date === null) ? true : false} onClick={() => this.handlePreviousNextDate(`previous`)} >
                        <span className="float-left chevron chevron-left arrow-left" style={(previous_date === null) ? { color: "rgb(153 153 151)", opacity: "0.7", zIndex: 1 } : null} />
                      </a>
                    </span>
                    <span className="dashboard_calendar ml-4 " >
                      <DatePicker
                        id="dash_cal"
                        name="dash_cal"
                        format="dddd, DD MMM YYYY"
                        allowClear={false}
                        className="calendar_dash datetimepicker"
                        selected={queue_date}
                        value={moment(queue_date)}
                        onChange={this.handleAppointmentDate}
                      />
                    </span>
                    <span disabled="disabled" className="">
                      <a disabled={(next_date === null) ? true : false} onClick={() => this.handlePreviousNextDate(`next`)} >
                        <span className="float-right chevron chevron-right arrow-right ml-2" style={(next_date === null) ? { color: "rgb(153 153 151)", opacity: "0.7", zIndex: 1 } : null} />
                      </a>
                    </span>
                  </div>

                  <div className='col-xl-2 col-lg-3 col-3 offset-xl-3 offset-lg-1 offset-1 text-right'>
                    {((!this.state.loading) && is_junior_doctor == true && (this.state.activeTab === 'worklist' || this.state.activeTab === 'completed' || this.state.activeTab === 'pending')) ? <>
                      <div className='button-wrap'>
                        <div className="btn btn-primary px-4 py-1 text-uppercase outer-btn" style={{ width: `${seniorDoctorId.length > 0 ? '100%' : 'auto'}` }}
                          onClick={() => { this.setState({ doctorSelectionModal: true }) }}>
                          {seniorDoctorId.length > 0 ? seniorDoctorId.length + ' ' + 'DOCTOR SELECTED' : 'DOCTOR SELECTION'}
                        </div>
                        {seniorDoctorId.length > 0 ?
                          <div className="btn btn-primary py-1 text-uppercase inner-btn" onClick={() => { this.clearSeniorDoctorList() }}
                          >
                            X
                          </div> : null}
                      </div>

                    </>
                      : null}
                  </div>

                  <div className={LS_SERVICE.get('user_type') == USERTYPES.nurse && APPS_FEATURES_LIST.select_doctor_dropdown ? 'col-4 col-xl-4 col-lg-4   pr-0' : 'col-4 col-xl-4 col-lg-4  pr-0'} >
                    {(!this.state.loading && this.state?.slots?.length > 0 ) && (
                      <>

                        {/* <div className=" form-group mb-0 input-height-small hasdata">
                          <select className="form-control drug-input mandatory-field" onChange={(e) => this.hospitalChange(e.target.value)}>
                            {this.state.slots?.map((slot, index) => (
                              <option title={slot.name} key={index} value={slot.id} selected={slot.id == LS_SERVICE.get('slot_hospital_id')}>{slot.name}</option>
                            ))}
                          </select>
                          <label className="required" htmlFor="">Select Hospital<span>*</span></label>
                        </div> */}
                        <Form layout="vertical" className='emrfrm'>
                          <div className="antInputItem position-relative hasdata mb-0 margin_change" id="">
                            <Form.Item name="Select Hospital" label="Select Hospital"
                            >
                              <Select id="Select Hospital" name="medic_name" style={{ width: 100 + '%' }}
                                optionFilterProp="children"
                                className="select-diagnosis dashboard_select_height"
                                onChange={(value, optionInfo) => this.hospitalChange(value, optionInfo)}
                                defaultValue={deaultHosiptal.id}
                                disabled={true}
                                value={deaultHosiptal.name}
                                filterOption={(input, option) => {
                                  return option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                }}
                              >
                                {this.state.slots?.map((slot, index) => (
                                  <Option title={slot.name} key={index} value={slot.id} assistant_number={slot.assistance_number} >{slot.name}</Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </Form>
                      </>
                    )}


                  </div>
                </div> : null}
              </div>



              <div className="row">

                {/* {LS_SERVICE.get('user_type') == USERTYPES.nurse && APPS_FEATURES_LIST.select_doctor_dropdown ? <div className="col-2">
                  <div className="ml-3 form-group hasdata">
                    <select className="form-control drug-input mandatory-field" onChange={(e) => this.nurseSelectedDoctor(e.target.value)}>
                      <option selected="selected" value="" disabled="disabled">Select Doctor</option>
                      {this.state.nurseDoctors.map((doctor, index) => (
                        <option key={index} value={doctor.staff_id} selected={this.state.nurseDoctorId == doctor.staff_id}>Dr {doctor.first_name} {doctor.last_name} | {doctor.speciality.length > 0 ? doctor.speciality[0]['names'] : null}</option>
                      ))}
                    </select>
                    <label className="required" htmlFor="">Select Doctor<span>*</span></label>
                  </div>
                </div> : null} */}
                {!this.state.loading ? (
                  <>
                    <div className="col-xl-8 col-8" id="queue-top-navigation-bar"
                    >
                      <ul className="nav nav-pills justify-content-start myhealthcare-tabs " role="tablist">
                        {this.state.is_vc_opd_enabled ? (
                          <>
                            <li className="nav-item">
                              <a id="work-list-tab" href="#work-list" className={`nav-link ${activeTab === TAB_NAMES[0] ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="list-tab" aria-selected="true" ref='WorklistTab'
                                onClick={e => this.handleTabChange(e, TAB_NAMES[0])}>
                                Worklist {this.state.worklist_count > 0 ? <span id="patient-queue-worklist_count">({this.state.worklist_count})</span> : null}
                              </a>
                            </li>
                            <li className="nav-item">
                              <a id="completed-tab" href="#completed-consults" className={`nav-link ${activeTab === TAB_NAMES[1] ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="order-set-tab" aria-selected="false" ref='CompletedTab'
                                onClick={e => this.handleTabChange(e, TAB_NAMES[1])}>
                                Completed (OPD/VC) &nbsp; {this.state.completed_count > 0 ? <span id="patient-queue-completed_count">({this.state.completed_count})</span> : null}
                              </a>
                            </li>
                            {LS_SERVICE.get('user_type') == USERTYPES.doctor && this.state.review_count > 0 ?
                              <li className="nav-item nav-width">
                                <a id="review-tab" href="#review-result" className={`nav-link ${activeTab === TAB_NAMES[5] ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="order-set-tab" aria-selected="false" ref='ReviewTab'
                                  onClick={e => this.handleTabChange(e, TAB_NAMES[5])}>
                                  Review Report &nbsp; {this.state.review_count > 0 ? <span id="patient-queue-review_count">({this.state.review_count})</span> : null}
                                </a>
                              </li> : null
                            }

                            {/* <li className="nav-item nav-reports">
                              <a id="reports-tab" href="#reports" className={`nav-link ${activeTab === TAB_NAMES[3] ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="order-set-tab" aria-selected="false" ref=''
                              >
                                Reports<span id="patient-queue-homecare_count">(1)</span>
                              </a>
                            </li> */}

                          </>
                        ) : null}

                        {LS_SERVICE.get('user_type') == USERTYPES.doctor && moment().format('YYYY-MM-DD') == moment(this.state.queue_date).format('YYYY-MM-DD') ? <li className="nav-item nav-width-pen">
                          <a id="pending-tab" href="#pending" className={`nav-link ${activeTab === TAB_NAMES[2] ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="pending-tab" aria-selected="false" ref='PendingTab'
                            onClick={e => this.handleTabChange(e, TAB_NAMES[2])}>
                            Pending {this.state.pending_count > 0 ? <span id="patient-queue-pending_count">({this.state.pending_count})</span> : null}
                          </a>
                        </li> : null}

                        {
                          this.state.is_doctor_homecare_active || (LS_SERVICE.get('is_homecarecall') == true && LS_SERVICE.get('user_type') == USERTYPES.nurse)
                        ?
                          <>
                            <li className="nav-item">
                              <a id="homecare-tab" href="#homecare" className={`nav-link ${activeTab === TAB_NAMES[3] ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="order-set-tab" aria-selected="false" ref='HomecareTab'
                                onClick={e => this.handleTabChange(e, TAB_NAMES[3])}>
                                HomeCare {this.state.homecare_upcoming_count > 0 ? <span id="patient-queue-homecare_count">({this.state.homecare_upcoming_count})</span> : null}
                              </a>
                            </li>

                            <li className="nav-item">
                              <a id="homecare-completed-tab" href="#homecare_completed" className={`nav-link ${activeTab === TAB_NAMES[4] ? 'active' : ''}`} data-toggle="pill" role="tab" aria-controls="order-set-tab" aria-selected="false" ref='HomecareCompletedTab'
                                onClick={e => this.handleTabChange(e, TAB_NAMES[4])}>
                                Completed (HC)&nbsp; {this.state.homecare_completed_count > 0 ? <span id="patient-queue-homecare_count">({this.state.homecare_completed_count})</span> : null}
                              </a>
                            </li>
                          </>
                        :
                          null
                        }

                      </ul>
                    </div>
                    <div className='col-4 '>
                      {LS_SERVICE.get('user_type') == USERTYPES.nurse && APPS_FEATURES_LIST.select_doctor_dropdown && (this.state.activeTab === 'worklist' || this.state.activeTab === 'completed') ?
                        /* ##### Doctor Selection Cta */
                        <div className='form-group text-right'>
                          <div className="btn btn-primary px-4 text-uppercase"
                            onClick={() => { this.setState({ doctorSelectionModal: true }) }}>
                            DOCTOR SELECTION
                          </div>
                        </div>
                        : null}
                      <div className='row'>
                        <div className='col'>
                          {SearchCondition.includes(activeTab) ?
                            <div className="form-group input-height-small mb-4" style={{ position: "relative" }}>
                              <input name="name" type="text" id="patient_name" className="form-control" placeholder="Patient by Name/UHID/Mobile No." onChange={this.onSearch} value={this.state.search_keyword} maxLength="100" autoComplete="off" />
                              <label className="required" htmlFor="patient_name">Search by Name/UHID/Mobile No.</label>
                              <img className="mr-2 worlist_search-icon" src={searchIcon} alt="" />
                            </div>
                            : null}
                        </div>
                        {(LS_SERVICE.get('user_type') === USERTYPES.doctor) ?
                          this.callNextNew()
                          : null}

                      </div>

                    </div>

                    {/* {(LS_SERVICE.get('user_type') == USERTYPES.doctor) ?
                      <div className={'col-1'}>
                        {this.callNextNew()}
                      </div>
                      : null} */}
                    {/* <div className={LS_SERVICE.get('user_type') == USERTYPES.nurse && APPS_FEATURES_LIST.select_doctor_dropdown ? 'col-3' : 'col-2 '}> */}
                    {/* {(!this.state.loading && this.state?.slots?.length > 0) && (
                        <>

                          <div className="ml-3 form-group hasdata">
                            <select className="form-control drug-input mandatory-field" onChange={(e) => this.hospitalChange(e.target.value)} disabled={true}>
                              {this.state.slots?.map((slot, index) => (
                                <option title={slot.name} key={index} value={slot.id} selected={slot.id == LS_SERVICE.get('slot_hospital_id')}>{slot.name}</option>
                              ))}
                            </select>
                            <label className="required" htmlFor="">Select Hospital<span>*</span></label>
                          </div>
                        </>
                      )} */}
                    {/* 
                      {LS_SERVICE.get('user_type') == USERTYPES.nurse && APPS_FEATURES_LIST.select_doctor_dropdown && this.state.is_vc_opd_enabled && (this.state.activeTab === 'worklist' || this.state.activeTab === 'completed') ? */}
                    {/* <div className='ml-3 form-group text-right'>
                          <div className="btn btn-primary px-4 text-uppercase"
                            onClick={() => { this.setState({ doctorSelectionModal: true }) }}>
                            DOCTOR SELECTION
                          </div>
                        </div>
                        : null} */}
                    {/* </div> */}
                  </>
                ) : null}

                <div className="col-12" id="call-next">
                  <div className="float-right mr-3" id="call-next-div">
                    {LS_SERVICE.get('user_type') == USERTYPES.doctor ? this.callNextGlobal() : null}
                  </div>
                </div>


                <div className="col-12">
                  <div className="tab-content">
                    {(this.state.activeTab.includes('homecare') && !this.state.loading) && (
                      <div className="emrfrm mb-3 w-50">
                        <div></div>
                        <div className="form-group input-group mb-0">
                          <input name="hcCompSearch" type="text" className="form-control" placeholder="Search by patient name or MH number"
                            onChange={this.handleHCSearch} value={this.state.hcCompSearch} maxLength="100" autoComplete="off" />
                          <div className="input-group-append">
                            <span className="input-group-text bg-white">
                              <img src={SEARCH_ICO} alt="icon" className="img-responsive" />
                            </span>
                          </div>
                          <label className="required" htmlFor="patient_name">Search by patient name or MH number</label>
                        </div>
                      </div>
                    )}

                    <div className={`tab-pane fade ${activeTab === TAB_NAMES[0] ? 'active' : ''}`} role="tabpanel" id="work-list" ref='WorklistPanel'>
                      <div className="row">
                        <div className="col-12 mb-4 px-3" id="patient-queue-work-list">

                          {this.state.loading ? this.loader() : this.worklist()}

                        </div>
                      </div>
                    </div>

                    <div className={`tab-pane fade ${activeTab === TAB_NAMES[1] ? 'active' : ''}`} role="tabpanel" id="completed-consults" ref='CompletedPanel'>
                      <div className="row">
                        <div className="col-12 mb-4" id="patient-queue-completed">

                          {this.state.loading ? this.loader() : this.completed()}

                        </div>
                      </div>
                    </div>

                    <div className={`tab-pane fade ${activeTab === TAB_NAMES[5] ? 'active' : ''}`} role="tabpanel" id="completed-consults" ref='CompletedPanel'>
                      <div className="row">
                        <div className="col-12 mb-4 px-3" id="patient-queue-completed">

                          {this.state.loading ? this.loader() : this.review()}

                        </div>
                      </div>
                    </div>

                    <div className={`tab-pane fade ${activeTab === TAB_NAMES[2] ? 'active' : ''}`} role="tabpanel" id="pending" ref='PendingPanel'>
                      <div className="row">
                        <div className="col-12 mb-4" id="patient-queue-pending">

                          {this.state.loading ? this.loader() : this.pending()}

                        </div>
                      </div>
                    </div>

                    <div className={`tab-pane fade ${activeTab === TAB_NAMES[3] ? 'active' : ''}`} role="tabpanel" id="homecare" ref='HomecarePanel'>
                      <div className="row">
                        <div className="col-12 mb-4" id="patient-queue-homecare">

                          {(activeTab === TAB_NAMES[3] && (this.state.loading)) ? this.loader() : (
                            <>
                              {this.homecare('homecare')}
                              <div className="col-12 mb-5">
                                <Pagination onChange={this.homecare_paginate} defaultCurrent={this.state.homecare_currentpage} total={this.state.homecare_totaldata} pageSize={this.state.per_page} />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={`tab-pane fade ${activeTab === TAB_NAMES[4] ? 'active' : ''}`} role="tabpanel" id="homecare_completed" ref='HomecareCompletedPanel'>
                      <div className="row">
                        <div className="col-12 mb-4" id="patient-queue-homecare">

                          {(activeTab === TAB_NAMES[4] && (this.state.loading)) ? this.loader() : (
                            <>
                              {this.homecare('homecare_completed')}
                              <div className="col-12 mb-5">
                                <Pagination onChange={this.homecare_paginate} defaultCurrent={this.state.homecare_currentpage} total={this.state.homecare_totaldata} pageSize={this.state.per_page} />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            {/* <Modal
              title={false}
              closable={false}
              visible={this.state.isModalVisible}
              footer={false}
            >
              <div className="row">
                <div className="col-12 my-3 text-center">
                  <h6 id="pending-patients-count">You have {this.state.pending_count} consults pending. Please complete them at the earliest</h6>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="btn btn-outline-secondary px-5" onClick={this.gotoWorklist}>Go to worklist</div>
                <div className="btn btn-primary px-5" onClick={this.gotoPending}>Go to pending</div>
              </div>
            </Modal> */}

            <Modal
              title={false}
              closable={false}
              visible={this.state.practiceNotStarted}
              footer={false}
            >
              <div className="row">
                <div className="col-12 my-3 text-center">
                  <h6>Do you want to Start Practice?</h6>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                <div className="btn btn-outline-secondary px-5" onClick={() => this.dontStartPractice('pastvisit')}>No</div>
                <div className="btn btn-primary px-5" onClick={() => this.yesStartPractice()}>Yes</div>
              </div>
            </Modal>

            <Modal
              title="View Prescription"
              visible={this.state.showRxModal}
              footer={false}
              width={850}
              onCancel={() => { this.setState({ showRxModal: false, rxPdfPath: null, rxPdfPrint: false }) }}
              style={{ top: 20 }}
            >
              <div className="row">
                <div className="col-12 my-3 text-center">
                  {(this.state.showLoader) ? LOADER_RED :
                    this.state.rxPdfPath != null ? <iframe id="pdfViewerIframe" src={this.state.rxPdfPath} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700" onLoad={() => {
                      const { rxPdfPrint } = this.state;
                      const pdfiframe = document.getElementById('pdfViewerIframe');
                      const browser = Bowser.parse(window.navigator.userAgent).browser.name;
                      if (browser == "Safari" && rxPdfPrint) {
                        setTimeout(() => pdfiframe.contentWindow.print(), 2000);
                      }
                      if (browser != "Safari" && rxPdfPrint) {
                        pdfiframe.contentWindow.print();
                      }
                    }}>
                      {LOADER_RED}
                    </iframe> : null}
                </div>
              </div>
            </Modal>


            {/* <!-- Start practice alert  --> */}
            {/* <div className="modal" id="start-practice-alert" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 my-3 text-center">
                      <h6>Do you want to Start Practice?</h6>
                    </div>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <a href="#" className="btn btn-outline-secondary px-4" id="startPracticeAlertConfirmNo" data-dismiss="modal">No</a>
                    <div className="btn btn-primary px-4" id="startPracticeAlertConfirmOK" data-dismiss="modal">
                      Yes
              </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          </div>

          {/* <div className="modal fade" aria-hidden="true" id="view_visit_rx_modal" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title">Visit Prescription</h6>
                <button type="button" className="close" data-dismiss="modal">×</button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 my-3 text-center">
                    <iframe frameBorder="0" scrolling="no" width="100%" height="700" id="frame">
                      <div className="spinner red"><div className="bounce1"></div><div className="bounce2"></div><div className="bounce3"></div></div>
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}


          {/* <Modal
          title={false}
          closable={false}
          visible={this.state.isModalVisible}
          footer={false}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              <h6 id="pending-patients-count">You have 2 consults pending. Please complete them at the earliest</h6>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <div className="btn btn-outline-secondary px-5" onClick={this.gotoWorklist}>Go to worklist</div>
            <div className="btn btn-primary px-5" onClick={this.gotoPending}>Go to pending</div>
          </div>
        </Modal> */}
        </Spin>

        {/* Modal for Doctor Selection */}
        <Modal
          title='View Appointments of Selected Doctors'
          visible={this.state.doctorSelectionModal}
          onCancel={() => this.handleCancel()}
          className="doctor_select_modal"
          width="992px"
          footer={(this.state.nurseDoctorsList != undefined && this.state.nurseDoctorsList.length > 0) || is_junior_doctor == true ? [
            <div className='col-12 text-center'>
              <div onClick={() => this.handleCancel()} className="btn btn-primary px-4 text-uppercase">
                CONFIRM</div>
            </div>
          ] : null}
        >{is_junior_doctor == false && senior_doctor.length == 0 ?
          <div className='container-fluid doctor_selection_container'>
            {this.state.nurseDoctorsList != undefined && this.state.nurseDoctorsList.length > 0 ?
              this.state.nurseDoctorsList?.map((element) => {
                return (<>
                  <div className='row my-2'>
                    <div className='col-12'>
                      <h6 className='text-secondary'>{element.speciality_name}</h6>
                    </div>
                  </div>
                  {element.data?.map((doctor, index) => {
                    return (<>
                      <div className='row '>
                        <div className='col-12 my-1'>
                          <Checkbox
                            name="doctor1"
                            key={index}
                            value={doctor.id}
                            arrival={doctor.is_mark_arrival}
                            data={doctor}
                            onChange={this.nurseSelectedDoctor}
                            checked={this.state.nurseDoctorId.includes(doctor.id)}
                          >
                            <span className='text-dark'>{doctor.name}</span>
                          </Checkbox>
                        </div>
                      </div>
                    </>);
                  })}
                </>);
              })
              :
              <div className='row my-2'>
                <div className='col-12'>
                  <h6 className='text-secondary'>No doctor mapped to nurse.</h6>
                </div>
              </div>
            }

          </div> :
          <div className='container-fluid doctor_selection_container'>
            {senior_doctor != undefined && senior_doctor.length > 0 ?
              senior_doctor?.map((doctor, index) => {
                let doctor_name = 'Dr' + " " + doctor.empName;
                doctor_name += doctor.middle_name != null ? doctor.middle_name + " " : '';
                doctor_name += doctor.last_name != null ? doctor.last_name + " " : '';
                return (<>
                  <div className='row '>
                    <div className='col-12 my-1'>
                      <Checkbox
                        name="doctor1"
                        key={index}
                        value={doctor.employeeId}
                        // arrival={doctor.is_mark_arrival}
                        data={doctor}
                        onChange={this.seniorSelectedDoctor}
                        checked={this.state.seniorDoctorId.includes(doctor.employeeId)}
                      >
                        <span className='text-dark'>{doctor_name}</span>
                      </Checkbox>
                    </div>
                  </div>
                </>);
              })
              :
              <div className='row my-2'>
                <div className='col-12'>
                  <h6 className='text-secondary'>No doctor mapped to junior doctor</h6>
                </div>
              </div>
            }
          </div>

          }
        </Modal>
      </>
    )
  }
}

export default SearchPatient;
